import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/shr/environment';

@Injectable()
export class CstService {

  constructor(protected httpClient: HttpClient) { }

  constants: any;

  public getJsonData(): Observable<any> {
    return this.httpClient.get(environment.cstJsonFile);
  }

  public getConstants() {
    this.getJsonData().subscribe(
      constants => {
        this.constants = constants;
      });
  }

  public getComptesLabels(key): string {
    // this.getConstants();
    let label = '';
    label = this.constants.filter(function (key) {
      return this.constants.comptesLabels.key = key;
    });
    return label;
  }




}
