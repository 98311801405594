import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdDialogsHelperComponent } from './md-dialogs-helper.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MatDialogModule, MatButtonModule } from '@angular/material';

@NgModule({
  declarations: [ ConfirmDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule
  ],
  entryComponents: [ConfirmDialogComponent],
  providers: [MdDialogsHelperComponent]
})
export class MdDialogsHelperModule { }
