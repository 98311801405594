import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-informations-reglementaires',
  templateUrl: './informations-reglementaires.component.html',
  styleUrls: ['./informations-reglementaires.component.css']
})
export class InformationsReglementairesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0, 0);
  }

}
