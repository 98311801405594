import { Injectable } from '@angular/core';

const TOKEN_KEY = btoa('AuthToken');
const ROLES = btoa('CMSRoles');
const ROLE = btoa('role');
const ROLET = btoa('rolet');
const ROLEA = btoa('rolea');
const ROLEST = btoa('rolest');
/** service mobile tan */
const ROLETANSMS = btoa('roletansms');
const ROLETAN1 = btoa('roletan1');
const ROLETAN2 = btoa('roletan2');
const PFSESSION = btoa('CMSPfSessions');
const USER_ID = btoa('userId');
const USER_ID_MEM = btoa('userIdMem');

const TOKEN_IDLE = 'ng2Idle.main.idling';



@Injectable()
export class StorageService {

  constructor() { }

  signOut() {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(TOKEN_IDLE);
    window.localStorage.removeItem(TOKEN_IDLE);
    window.sessionStorage.removeItem(ROLES);
    window.sessionStorage.removeItem(USER_ID);
    window.sessionStorage.removeItem(ROLE);
    window.sessionStorage.removeItem(ROLET);
    window.sessionStorage.removeItem(ROLEA);
    window.sessionStorage.removeItem(ROLEST);
    window.sessionStorage.clear();
  }

  public saveRoles(roles: any) {
    window.sessionStorage.removeItem(ROLES);
    window.sessionStorage.setItem(ROLES, btoa(roles));
  }
  public getRoles(): string {
    return atob(sessionStorage.getItem(ROLES));
  }

  public removeRoles() {
    sessionStorage.removeItem(ROLES);
  }

  public savepfSession(pfsessions: any) {
    window.sessionStorage.removeItem(PFSESSION);
    window.sessionStorage.setItem(PFSESSION, btoa(pfsessions));
  }
  public getpfSession(): string {
    return atob(sessionStorage.getItem(PFSESSION));
  }

  public removepfSession() {
    sessionStorage.removeItem(PFSESSION);
  }

  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, btoa(token));
  }

  public saveUserId(userId: string) {
    window.sessionStorage.removeItem(USER_ID);
    window.sessionStorage.setItem(USER_ID, btoa(userId));
  }

  public saveRole(role: string) {
    window.sessionStorage.removeItem(ROLE);
    window.sessionStorage.setItem(ROLE, btoa(role));
  }

  public saveRoleT(role: string) {
    window.sessionStorage.removeItem(ROLET);
    window.sessionStorage.setItem(ROLET, btoa(role));
  }

  public saveRoleA(role: string) {
    window.sessionStorage.removeItem(ROLEA);
    window.sessionStorage.setItem(ROLEA, btoa(role));
  }

  public saveRoleST(role: string) {
    window.sessionStorage.removeItem(ROLEST);
    window.sessionStorage.setItem(ROLEST, btoa(role));
  }
/** service mobiletan */
public saveRoleTANSMS(role: string) {
  window.sessionStorage.removeItem(ROLETANSMS);
  window.sessionStorage.setItem(ROLETANSMS, btoa(role));
}
public saveRoleTAN1(role: string) {
  window.sessionStorage.removeItem(ROLETAN1);
  window.sessionStorage.setItem(ROLETAN1, btoa(role));
}
public saveRoleTAN2(role: string) {
  window.sessionStorage.removeItem(ROLETAN2);
  window.sessionStorage.setItem(ROLETAN2, btoa(role));
}

  public saveUserIdMem(userIdMem: string) {
    window.localStorage.removeItem(USER_ID_MEM);
    window.localStorage.setItem(USER_ID_MEM, btoa(userIdMem));
  }

  public getToken(): string {
    if (sessionStorage.getItem(TOKEN_KEY)) {
      return atob(sessionStorage.getItem(TOKEN_KEY));
    }
  }

  public removeToken() {
    sessionStorage.removeItem(TOKEN_KEY);
  }


  public getUserId(): string {
    if (sessionStorage.getItem(USER_ID)) {
      return atob(sessionStorage.getItem(USER_ID));
    }
  }

  public getRole(): string {
    if (sessionStorage.getItem(ROLE)) {
      return atob(sessionStorage.getItem(ROLE));
    }
  }

  public getRoleT(): string {
    if (sessionStorage.getItem(ROLET)) {
      return atob(sessionStorage.getItem(ROLET));
    }
  }

  public getRoleA(): string {
    if (sessionStorage.getItem(ROLEA)) {
      return atob(sessionStorage.getItem(ROLEA));
    }
  }

  public getRoleST(): string {
    if (sessionStorage.getItem(ROLEST)) {
      return atob(sessionStorage.getItem(ROLEST));
    }
  }
  /** service mobile tan */
  public getRoleTANSMS(): string {
    if (sessionStorage.getItem(ROLETANSMS)) {
      return atob(sessionStorage.getItem(ROLETANSMS));
    }
  }
  public getRoleTAN1(): string {
    if (sessionStorage.getItem(ROLETAN1)) {
      return atob(sessionStorage.getItem(ROLETAN1));
    }
  }
  public getRoleTAN2(): string {
    if (sessionStorage.getItem(ROLETAN2)) {
      return atob(sessionStorage.getItem(ROLETAN2));
    }
  }
  public removeUserId() {
    sessionStorage.removeItem(USER_ID);
  }

  public removeRole() {
    sessionStorage.removeItem(ROLE);
  }

  public getUserIdMem(): string {
    if (localStorage.getItem(USER_ID_MEM)) {
      return atob(localStorage.getItem(USER_ID_MEM));
    }
    return '';
  }

  public removeUserIdMem() {
    localStorage.removeItem(USER_ID_MEM);
  }
}

