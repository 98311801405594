import { IModalDialog, IModalDialogOptions, IModalDialogButton } from 'ngx-modal-dialog';
import { Component, ComponentRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mif2PF-modal-pf',
  templateUrl: './modal-pf.component.html',
  styleUrls: ['./modal-pf.component.css']
})
export class ModalPfComponent {

  title = '';
  scoreConfigPF = '';
  constructor(public activeModal: NgbActiveModal) {

  }


}
