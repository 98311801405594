import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { Observable, Subject } from "rxjs";
import { environment } from "../../../../environments/shr/environment";
import { environment as environmentWla } from "../../../../environments/wla/environment";
import { Constants } from "./constants/constants";
import { BaseModel } from "./models/client.model";
@Injectable()
export class MwService {
  constructor(
    protected httpClient: HttpClient,
    public router: Router,
    private logger: NGXLogger
  ) {}
  private subject = new Subject<any>();

  public handleClmError(service, error): BaseModel {
    if (
      error.error.errorCode == "500" ||
      error.status == 500 ||
      error.status == 503 ||
      error.status == "504"
    ) {
      if (service != "login") {
        this.router.navigate(["/euroWebFr/-"], {
          queryParams: { target: "logout" },
        });
      }
    }

    this.logger.info("le message d'erreur : " + JSON.stringify(error, null, 2));
    this.logger.info("error.status : " + error.status);
    const message = new BaseModel();

    if (error.error) {
      message.errorCode = error.error.errorCode;
      message.errorDesc = error.error.errorDesc;
      message.errorOrigin = error.error.errorOrigin;
      message.errorSeverity = error.error.errorSeverity;
    }
    if (error.status == 403 || message.errorCode == "403") {
      message.errorCode = "403";
      message.errorDesc = Constants.SERVICE_ACCES_DENIED;
    }
    if (
      message.errorCode === "500" ||
      error.status == 500 ||
      error.status == 503 ||
      error.status == "504"
    ) {
      message.errorDesc = "[F] : " + service + Constants.SERVICE_INTERNAL_ERROR;
    }

    return message;
  }
  public handleError(service, error): BaseModel {
    if (
      error.error.errorCode == "500" ||
      error.status == 500 ||
      error.status == 503 ||
      error.status == "504"
    ) {
      if (service != "login" && service != "forgotPin") {
        this.router.navigate(["/logout"]);
      }
    }

    this.logger.info("le message d'erreur : " + JSON.stringify(error, null, 2));
    this.logger.info("error.status : " + error.status);
    const message = new BaseModel();

    if (error.error) {
      message.errorCode = error.error.errorCode;
      message.errorDesc = error.error.errorDesc;
      message.errorOrigin = error.error.errorOrigin;
      message.errorSeverity = error.error.errorSeverity;
    }
    if (error.status == 403 || message.errorCode == "403") {
      message.errorCode = "403";
      message.errorDesc = Constants.SERVICE_ACCES_DENIED;
    }
    if (
      message.errorCode === "500" ||
      error.status == 500 ||
      error.status == 503 ||
      error.status == "504"
    ) {
      message.errorDesc = "[F] : " + service + Constants.SERVICE_INTERNAL_ERROR;
    }

    return message;
  }

  // Ressources USER
  // login et logout ce sont des services commun pour sgp ,psa et clm
  public doLogin(userId, nip, siteId) {
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );
    return this.httpClient.post<any>(
      environment.gtwUrl + Constants.SERVICE_AUTH,
      { username: userId, password: nip, siteId: siteId },
      { headers: headers, observe: "response" }
    );
  }

  public doLogout(user_id) {
    return this.httpClient.get<any>(
      environment.gtwUrl + Constants.SERVICE_LOGOUT + `/${user_id}`
    );
  }
  // services pour sgp
  public getEmanager(userId) {
    return this.getHttpResponse(Constants.EMERGENCY_MANAGER, userId);
  }

  public getUserName(userId) {
    return this.getHttpResponse(Constants.SERVICE_USER_NAME, userId);
  }

  public getUserEmail(userId) {
    return this.getHttpResponse(Constants.SERVICE_USER_EMAIL, userId);
  }

  public getUserPhones(userId) {
    return this.getHttpResponse(Constants.SERVICE_USER_PHONES, userId);
  }

  public getUserAccounts(userId) {
    return this.getHttpResponse(Constants.SERVICE_USER_ACCOUNTS, userId);
  }

  public getUserRefresh(userId) {
    return this.getHttpResponse(Constants.SERVICE_USER_REFRESH, userId);
  }

  public getUserAllMif2(userId) {
    return this.getHttpResponse(Constants.SERVICE_USER_ALL_MIF2, userId);
  }

  public getUserHeaderInfo(userId) {
    return this.getHttpResponse(Constants.SERVICE_USER_HEADER_INFO, userId);
  }
  public getUserHeaderNameInfo(userId) {
    return this.getHttpResponse(
      Constants.SERVICE_USER_HEADER_NAME_INFO,
      userId
    );
  }
  public getUserHeaderNameInfoRib(userId) {
    return this.getHttpResponse(
      Constants.SERVICE_USER_HEADER_NAME_INFO_RIB,
      userId
    );
  }
  public getUserMif2(userId) {
    return this.getHttpResponse(Constants.SERVICE_USER_MIF2, userId);
  }

  public getBackPwd(body, siteId, mmid, token) {
    const headers = new HttpHeaders({
      "content-type": "application/json",
      tokenRecaptcha: token,
      apikey: environmentWla.apiKey,
      application: "cpp",
    });
    return this.httpClient.post<any>(
      environment.genericAuthUrl +
        Constants.SERVICE_GETBACKPIN +
        "/" +
        siteId +
        "/" +
        mmid,
      JSON.stringify(body),
      { headers }
    );
  }

  // Ressource ACCOUNT

  public getAccountRibPdf(accountNo) {
    return this.getHttpResponseBlob(
      Constants.SERVICE_ACCOUNT_RIB_PDF,
      accountNo
    );
  }

  public getAccountChequePdf(accountNo) {
    return this.getHttpResponseBlob(
      Constants.SERVICE_ACCOUNT_CHEQUE_PDF,
      accountNo
    );
  }

  public getHttpResponseBlob(serviceName, id): Observable<any> {
    const httpOptions = {
      responseType: "blob" as "json",
    };
    return this.httpClient.get(
      environment.apiUrl + serviceName + `/${id}`,
      httpOptions
    );
  }

  // Informations concernant le login
  public getLoginInfo(userId) {
    return this.getHttpResponse(Constants.SERVICE_LOGIN_INFO, userId);
  }

  public getTanInfo(userId) {
    return this.getHttpResponse(Constants.SERVICE_TAN_INFO, userId);
  }

  public getUserAddresses(userId) {
    return this.getHttpResponse(Constants.SERVICE_USER_ADDRESSES, userId);
  }

  public getUserTechnicalInfo(userId) {
    return this.getHttpResponse(Constants.SERVICE_USER_TECHNICAL_INFO, userId);
  }

  // MIF2

  public getMif2ScoresConfig() {
    return this.getHttpResponseS(Constants.SERVICE_MIF2_SCORES_CONFIG);
  }

  public getMif2FINPPTemplate() {
    return this.getHttpResponseS(Constants.SERVICE_MIF2_QFINPP_TEMPLATE);
  }

  public getMif2RSKPPTemplate() {
    return this.getHttpResponseS(Constants.SERVICE_MIF2_QRSKPP_TEMPLATE);
  }

  public loadTemplate(step, type, user_id, accountNo, origin) {
    return this.httpClient.get<any>(
      environment.apiUrl + Constants.SERVICE_MIF2_LOAD_TEMPLATE,
      {
        params: {
          typetemplate: type,
          user_id: user_id,
          origin: origin,
          accountNo: accountNo,
          step: step,
        },
      }
    );
  }

  // CAT SSO

  public ssoConnect(feId) {
    return this.getSsoConnectResponse(feId);
  }

  public getSsoConnectResponse(feId): Observable<any> {
    const params = new HttpParams().set("feId", feId);
    return this.httpClient.get<any>(
      environment.apiUrl + Constants.SERVICE_CAT_SSO_CONNECT,
      { params: params }
    );
  }

  // Rubrique syntèse des comptes
  public getUserAccountsSyntheseValo(userId) {
    return this.getHttpResponse(
      Constants.SERVICE_USER_ACCOUNTS_SYNTHESE_VALO,
      userId
    );
  }

  public getUserAccountsValo(userId) {
    return this.getHttpResponse(Constants.SERVICE_USER_ACCOUNTS_VALO, userId);
  }
  public getUserAccountsValoMif2(userId) {
    return this.getHttpResponse(
      Constants.SERVICE_USER_ACCOUNTS_VALO_MIF2,
      userId
    );
  }

  public getAccountsValoPdf(userId) {
    return this.getHttpResponseBlob(
      Constants.SERVICE_USER_ACCOUNTS_VALO_PDF,
      userId
    );
  }

  public getAccountsValoXls(userId) {
    return this.getHttpResponseBlob(
      Constants.SERVICE_USER_ACCOUNTS_VALO_XLS,
      userId
    );
  }

  // Anciennes méthodes à abandonner

  public getRIB(accountno) {
    return this.httpClient.post(environment.apiUrl + Constants.SERVICE_RIB, {
      accountno: accountno,
    });
  }

  public getMif2Definition(type, origin) {
    const formData: FormData = new FormData();
    formData.append("type", type);
    formData.append("origin", origin);

    return this.httpClient.post(
      environment.apiUrl + Constants.SERVICE_CRM_GET_MIF2_DEFINITION,
      formData
    );
  }

  // Services de mises à jour

  public changerMdp(id, pin, newPin) {
    const formData: FormData = new FormData();
    formData.append("id", id);
    formData.append("pin", pin);
    formData.append("pinNew", newPin);
    return this.getDataResponse(Constants.SERVICE_PIN_CHANGE, formData);
  }

  public getHttpResponse(serviceName, user_id): Observable<any> {
    return this.httpClient.get<any>(
      environment.apiUrl + serviceName + `/${user_id}`
    );
  }

  public getHttpResponseByIdtest(
    jsonRequest: any,
    serviceName,
    userid
  ): Observable<any> {
    return this.httpClient.put<any>(
      environment.apiUrl + "user/email" + `/${userid}` + jsonRequest,
      JSON.stringify(jsonRequest)
    );
  }
  public getHttpResponseS(serviceName): Observable<any> {
    return this.httpClient.get<any>(environment.apiUrl + serviceName);
  }

  public getDataResponseI(jsonRequest: any): Observable<any> {
    return this.httpClient.post<any>(
      environment.apiUrl + jsonRequest,
      JSON.stringify(jsonRequest)
    );
  }
  public getDataResponseUpdate(jsonRequest: any): Observable<any> {
    return this.httpClient.put<any>(
      environment.apiUrl + jsonRequest,
      JSON.stringify(jsonRequest)
    );
  }

  public getDataResponseUpdatetest(jsonRequest: any, userid): Observable<any> {
    return this.httpClient.put<any>(
      environment.apiUrl + "user/email" + `/${userid}` + jsonRequest,
      JSON.stringify(jsonRequest)
    );
  }
  public getDataResponse(serviceName, formData: FormData): Observable<any> {
    return this.httpClient.post<any>(
      environment.apiUrl + serviceName,
      formData
    );
  }
  public getDataResponsePost(serviceName, jsonRequest: any): Observable<any> {
    return this.httpClient.post(
      environment.apiUrl + serviceName,
      JSON.stringify(jsonRequest)
    );
  }

  PostObject(serviceName, body) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.httpClient.post<any>(
      environment.apiUrl + serviceName,
      JSON.stringify(body),
      {
        headers,
      }
    );
  }

  // Mobile TAN
  public registerPhone(prefix, phoneno) {
    const formData: FormData = new FormData();
    formData.append("countyCodePhoneno ", "+33");
    formData.append("prefix", prefix);
    formData.append("phoneNumber", phoneno);
    return this.getDataResponse(Constants.SERVICE_REGISTER_PHONE, formData);
  }

  public TanPhone() {
    return this.getHttpResponseS(Constants.SERVICE_TAN_PHONE);
  }

  public registerTan(tan) {
    const formData: FormData = new FormData();
    formData.append("tan", tan);
    return this.getDataResponse(Constants.SERVICE_REGISTER_TAN, formData);
  }

  public activateTan(tan) {
    const formData: FormData = new FormData();
    formData.append("tan", tan);

    return this.getDataResponse(Constants.SERVICE_ACTIVATE_TAN, formData);
  }

  public desactivateTan() {
    return this.getDataResponse(Constants.SERVICE_DESACTIVATE_TAN, null);
  }

  public getSessionTanStatus() {
    return this.getHttpResponseS(Constants.SERVICE_SESSION_TAN_STATUS);
  }

  public requestTan(svcName, retransmit, svcSignature) {
    const formData: FormData = new FormData();
    formData.append("svcName", svcName);
    formData.append("retransmit", retransmit);
    if (svcSignature) {
      formData.append("svcSignature", svcSignature);
    }
    return this.getDataResponse(Constants.SERVICE_REQUEST_TAN, formData);
  }

  public activateSessionTan(tan) {
    const formData: FormData = new FormData();
    formData.append("tan", tan);
    return this.getDataResponse(
      Constants.SERVICE_SESSION_TAN_ACTIVATE,
      formData
    );
  }
  public desactivateSessionTan() {
    return this.getDataResponse(
      Constants.SERVICE_SESSION_TAN_DESACTIVATE,
      null
    );
  }

  public updateSessionTanAtLogin(status) {
    const formData: FormData = new FormData();
    formData.append("status", status + "");
    return this.getDataResponse(
      Constants.SERVICE_SESSION_TAN_ATLOGIN,
      formData
    );
  }

  // Mobile TAN generic-api
  public registerPhone2(json) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this.httpClient.post<any>(
      environment.genericUrl + Constants.SERVICE_REGISTER_PHONE2,
      JSON.stringify(json),
      httpOptions
    );
  }
  public TanPhone2() {
    return this.httpClient.get<any>(
      environment.genericUrl + Constants.SERVICE_TAN_PHONE2
    );
  }
  // TODO: same service
  public getSessionTanStatus2() {
    return this.httpClient.get<any>(
      environment.genericUrl + Constants.SERVICE_SESSION_TAN_STATUS2
    );
  }
  public activateTan2(tan) {
    return this.httpClient.post<any>(
      environment.genericUrl + Constants.SERVICE_ACTIVATE_TAN2 + `/${tan}`,
      ""
    );
  }
  public deactivateTan2() {
    return this.httpClient.post<any>(
      environment.genericUrl + Constants.SERVICE_DESACTIVATE_TAN2,
      ""
    );
  }
  public registerTan2(tan) {
    return this.httpClient.post<any>(
      environment.genericUrl + Constants.SERVICE_REGISTER_TAN2 + `/${tan}`,
      ""
    );
  }

  public requestTan2(svcName, retransmit, svcSignature) {
    let body;
    body = {
      serviceName: svcName,
      retransmit: retransmit,
      serviceSignature: svcSignature,
    };
    return this.httpClient.post<any>(
      environment.genericUrl + Constants.SERVICE_REQUEST_TAN2,
      body
    );
  }
  public activateSessionTan2(tan) {
    return this.httpClient.post<any>(
      environment.genericUrl +
        Constants.SERVICE_SESSION_TAN_ACTIVATE2 +
        `/${tan}`,
      ""
    );
  }
  public deactivateSessionTan2() {
    return this.httpClient.post<any>(
      environment.genericUrl + Constants.SERVICE_SESSION_TAN_DESACTIVATE2,
      null
    );
  }
  public updateSessionTanAtLoginActivate() {
    // const formData: FormData = new FormData();
    // formData.append('status', status + '');
    return this.httpClient.post<any>(
      environment.genericUrl + Constants.SERVICE_SESSION_TAN_ATLOGIN2_ACTIVATE,
      null
    );
  }
  public updateSessionTanAtLoginDeactivate() {
    return this.httpClient.post<any>(
      environment.genericUrl +
        Constants.SERVICE_SESSION_TAN_ATLOGIN2_DEACTIVATE,
      null
    );
  }

  updateObject(body, user_id, serviceName) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.httpClient.put<any>(
      environment.apiUrl + serviceName + `/${user_id}`,
      JSON.stringify(body),
      {
        headers,
      }
    );
  }

  public deleteIban(iban, status, contractGroup) {
    const body = {
      iban: iban,
      status: status,
      contractGroup: contractGroup + "",
    };

    return this.PostObject(Constants.SERVICE_Iban_delete, body);
    // return this.getDataResponsePost(Constants.SERVICE_Iban_delete,body);
    // return this.httpClient.post<any>(environment.apiUrl + Constants.SERVICE_Iban_delete,
    // { 'iban': iban, 'status': status, 'contractGroup': contractGroup }, { headers: headers, observe: 'response' });
  }

  public updateMail(email, allow_adv_email, user_id) {
    const body = {
      email: email,
      allowAdvEmail: allow_adv_email,
      origin: Constants.ORIGIN,
      user_id: user_id,
    };
    return this.updateObject(body, user_id, Constants.SERVICE_USER_EMAIL);
  }

  public updatePhones(domicile, portable, professionnel, user_id) {
    const body = {
      domicile: domicile,
      portable: portable,
      professionel: professionnel,
      origin: Constants.ORIGIN,
      user_id: user_id,
    };

    return this.updateObject(body, user_id, Constants.SERVICE_USER_PHONES);
  }

  public getaccountDetails(accountNo, accounttype): Observable<any> {
    const params = new HttpParams().set("accounttype", accounttype);
    return this.httpClient.get<any>(
      environment.apiUrl +
        Constants.SERVICE_USER_ACCOUNTS_SYNTHESE_COMPTE +
        `/${accountNo}`,
      { params: params }
    );
  }

  public search(
    userId,
    start_record,
    max_record,
    accountNo,
    date_from,
    date_to,
    doc_status,
    docType
  ) {
    const body = {
      clientId: "COPARTIS",
      applicationId: "1",
      userId: userId,
      userRole: "CLI",
      startRecord: start_record,
      maxRecord: max_record,
      sortCriterion: "DATEDOC",
      sortDirection: "D",
      mentionType: "0",
      accountnos: accountNo,
      dateFrom: date_from,
      dateTo: date_to,
      docType: docType,
      docStatus: doc_status,
    };

    this.logger.debug("asterion body " + body);

    return this.PostObject(Constants.SERVICE_AsterionDocs_SEARCH, body);
  }

  public forgotPin(lastname, firstname, username) {
    const body = {
      lastname: lastname,
      firstname: firstname,
      username: username,
    };
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.httpClient.put<any>(
      environment.gtwUrl + Constants.SERVICE_FORGOTPIN,
      JSON.stringify(body),
      {
        headers,
      }
    );
  }

  public forgotPinN(lastname, firstname, username, siteId) {
    const body = {
      lastname: lastname,
      firstname: firstname,
      username: username,
      siteId: siteId,
    };
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.httpClient.put<any>(
      environment.gtwUrl + Constants.SERVICE_FORGOTPIN,
      JSON.stringify(body),
      {
        headers,
      }
    );
  }

  public getSessionTanAtLogin() {
    return true;
  }

  public getContractsGroup(): Observable<any> {
    return this.httpClient.get<any>(
      environment.apiUrl + Constants.SERVICE_ContractGroup_Load
    );
  }

  public getIbans(contractGroup): Observable<any> {
    const params = new HttpParams().set("contractGroup", contractGroup);
    return this.httpClient.get<any>(
      environment.apiUrl + Constants.SERVICE_Ibans_Load,
      { params: params }
    );
  }
  public getRSKAnswer(user_id, type, origin, accountNo) {
    return this.httpClient.get<any>(
      environment.apiUrl + Constants.SERVICE_MW_CrmGetMIF2Answers,
      {
        params: {
          type: type,
          user_id: user_id,
          origin: origin,
          accountNo: accountNo,
        },
      }
    );
  }
  public getFINPPAnswer(user_id, type, origin) {
    return this.httpClient.get<any>(
      environment.apiUrl + Constants.SERVICE_MW_CrmGetMIF2Answers,
      {
        params: {
          type: type,
          user_id: user_id,
          origin: origin,
        },
      }
    );
  }

  public getNationalities() {
    // return this.getHttpResponseS(Constants.SERVICE_MIF2_GET_Nationalities);
    return this.httpClient.get<any>(
      environment.apiUrl + Constants.SERVICE_MIF2_GET_Nationalities
    );
  }

  public getCspCodes() {
    // return this.getHttpResponseS(Constants.SERVICE_MIF2_GET_Nationalities);
    return this.httpClient.get<any>(
      environment.apiUrl + Constants.SERVICE_MIF2_GET_CspCodes
    );
  }

  public getPays() {
    return this.httpClient.get<any>(
      environment.apiUrl + Constants.SERVICE_MIF2_GET_PAYS
    );
  }

  public getSecteurs() {
    return this.httpClient.get<any>(
      environment.apiUrl + Constants.SERVICE_MIF2_GET_Secteurs
    );
  }
  public getProfessions() {
    return this.httpClient.get<any>(
      environment.apiUrl + Constants.SERVICE_MIF2_GET_Professions
    );
  }
  public validateMif2(type, user_id, answer, step, dosave) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    let json: any;
    json = {
      answer: answer,
      type: type,
      step: step,
      doSave: dosave,
    };
    return this.httpClient.post<any>(
      environment.apiUrl + Constants.SERVICE_VALIDATE_MIF2 + `/${user_id}`,
      JSON.stringify(json),
      {
        params: {},
        headers,
      }
    );
  }
  public validateMif2RSK(type, user_id, account, answer) {
    let json: any;
    json = {
      answer: answer,
      type: type,
      account: account,
      doSave: true,
    };
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.httpClient.post<any>(
      environment.apiUrl + Constants.SERVICE_VALIDATE_MIF2 + `/${user_id}`,
      JSON.stringify(json),
      {
        params: {},
        headers,
      }
    );
  }

  public addIban(
    beneficiaryFirstName,
    beneficiaryLastName,
    bic,
    contractGroup,
    iban,
    tan,
    validateOnly,
    civility
  ) {
    // const headers = new HttpHeaders().set('content-type', 'application/json');
    const formData: FormData = new FormData();
    formData.append("beneficiaryFirstName", beneficiaryFirstName);
    formData.append("beneficiaryLastName", beneficiaryLastName);
    formData.append("bic", bic);
    formData.append("contractGroup", contractGroup);
    formData.append("iban", iban);
    formData.append("civility", civility);
    if (tan) {
      formData.append("tan", tan);
    }
    formData.append("validateOnly", validateOnly);
    return this.getDataResponse(Constants.SERVICE_Iban_add, formData);
  }

  // services pour clm

  public clmCustConnect() {}

  // services pour pzp

  public getEntreprise(user_id) {
    return this.httpClient.get<any>(
      environment.apiUrl + Constants.SERVICE_Entreprise + `/${user_id}`
    );
  }

  public getEntreprisePdf(userId) {
    return this.getHttpResponseBlob(Constants.SERVICE_EntreprisePdf, userId);
  }

  public getOperations(user_id) {
    return this.httpClient.get<any>(
      environment.apiUrl + Constants.SERVICE_OPERATIONS_GET + `/${user_id}`
    );
  }

  public getMovementsHistoryPdf(userId) {
    return this.getHttpResponseBlob(
      Constants.SERVICE_OPERATIONS_GET_PDF,
      userId
    );
  }
  public getMovementsHistoryXls(userId) {
    return this.getHttpResponseBlob(
      Constants.SERVICE_OPERATIONS_GET_XLS,
      userId
    );
  }

  // services pour psa
  public doSearch(search) {
    // return this.httpClient.get<any>(environment.psaUrl +'clients' + `/${user_id}`);
    return this.httpClient.get<any>(environment.psaUrl + "clients");
  }

  envoyerData(data: any) {
    this.subject.next({ text: data });
  }

  getdata(): Observable<any> {
    return this.subject.asObservable();
  }
}
