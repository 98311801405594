import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'politiquedeconfidentialite-politique-de-confidentialite',
  templateUrl: './politique-de-confidentialite.component.html',
  styleUrls: ['./politique-de-confidentialite.component.css']
})
export class PolitiqueDeConfidentialiteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
