import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../../shared/services/auth/storage.service';

@Component({
  selector: 'nousdecouvrir-nous-decouvrir',
  templateUrl: './nous-decouvrir.component.html',
  styleUrls: ['./nous-decouvrir.component.css']
})
export class NousDecouvrirComponent implements OnInit {

  userId : string;
  constructor(private storage: StorageService) { }

  ngOnInit() {
    window.scroll(0, 0);

    this.userId = this.storage.getUserId();
  }

}
