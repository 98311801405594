import { Injectable, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Routes, RouterModule} from '@angular/router';
import { NousDecouvrirComponent } from './nous-decouvrir/nous-decouvrir.component';
import { NotreSolutionComponent } from './notre-solution/notre-solution.component';
import { NosValeursComponent } from './nos-valeurs/nos-valeurs.component';
import { NousContacterComponent } from './nous-contacter/nous-contacter.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';
import { PolitiqueDeConfidentialiteComponent } from './politique-de-confidentialite/politique-de-confidentialite.component';
import { InformationsReglementairesComponent } from './informations-reglementaires/informations-reglementaires.component';
import { CookiesComponent } from './cookies/cookies.component';
import { EspaceDonneesPersonnellesComponent } from './espace-donnees-personnelles/espace-donnees-personnelles.component';

const routes: Routes = [
  //  {
  //    path: '',
  //    redirectTo: 'nous-decouvrir',
  //    pathMatch: 'full',
  //  },
  {
    path: 'nous-decouvrir',
    component: NousDecouvrirComponent,
    data: { title: 'Nous découvrir | Copartis' }
  },
  {
    path: 'notre-solution',
    component: NotreSolutionComponent,
    data: { title: 'Notre solution | Copartis' }
  },
  {
    path: 'nos-valeurs',
    component: NosValeursComponent,
    data: { title: 'Nos valeurs | Copartis' }
  },
  {
    path: 'nous-contacter',
    component: NousContacterComponent,
    data: { title: 'Nous contacter | Copartis' }
  },
  {
    path: 'mentions-legales',
    component: MentionsLegalesComponent,
    data: { title: 'Mentions légales | Copartis' }
  }, {
    path: 'informations-reglementaires',
    component: InformationsReglementairesComponent,
    data: { title: 'Informations réglementaires Copartis' }
  }, {
    path: 'cookies',
    component: CookiesComponent,
    data: { title: 'Cookies | Copartis' }
  }, {
    path: 'espace-donnees-personnelles',
    component: EspaceDonneesPersonnellesComponent,
    data: { title: 'Espace Données Personnelles | Copartis' }
  },
  //  { path: '**', redirectTo: 'nous-decouvrir' }


];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EditoRoutingModule { }
