import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { NGXLogger } from "ngx-logger";
import { Subscription, SubscriptionLike as ISubscription } from "rxjs";
import { take } from "rxjs/operators";
import { environment } from "../../../../environments/wla/environment";
import { AlinkService } from "../../../shared/services/alink/alink.service";
import { AuthService } from "../../../shared/services/auth/auth.service";
import { StorageService } from "../../../shared/services/auth/storage.service";
import { CmsService } from "../../../shared/services/cms/cms.service";
import { Client } from "../../../shared/services/mw/models/client.model";
import { MwService } from "../../../shared/services/mw/mw.service";
import { TanService } from "../../../shared/services/tan/tan.service";
import { LoginComponent } from "../../pages/public/login/login.component";

@Component({
  selector: "shared-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  constructor(
    private alink: AlinkService,
    private cmsService: CmsService,
    private tanService: TanService,
    private modalService: NgbModal,
    public router: Router,
    private idle: Idle,
    private mwService: MwService,
    private authService: AuthService,
    private storage: StorageService,
    private logger: NGXLogger
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.authService.currentRestrictedMode.subscribe(
          (value) => (this.client.restrictedArea = value)
        );
      }
    });

    this.client = new Client();
    this.authService
      .isLoggedIn()
      // .pipe(timeout(20000))
      .subscribe((data) => {
        if (data) {
          const userId = this.storage.getUserId();
          this.mwService.getEmanager(userId).subscribe((em) => {
            if (em.inactivityTime) {
              this.inactivityTime = em.inactivityTime;
            }
            if (em.timeOut) {
              this.timeOut = em.timeOut;
            }
            this.emergencyMode = em.emergencyMode;
          });

          // Délai d'inactivité qui déclenche le compte à rebours du timeout.
          idle.setIdle(this.inactivityTime);

          // Durée du timeout.
          idle.setTimeout(this.timeOut);

          // Les actions qui reinitialisent le timeout :  clicks, scrolls, touches
          idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

          idle.onIdleEnd.subscribe(() => {
            this.idleEndCount++;
            // console.log('Vous n\'êtes plus inactif !');
          });

          idle.onTimeout.subscribe(() => {
            this.idleTimeoutCount++;
            // console.log('Timed out. Déconnexion!');
            this.timedOut = true;
            this.doLogout();
          });

          idle.onIdleStart.subscribe(() => {
            this.idleStartCount++;
            // console.log('Vous êtes inactif !!');
          });

          idle.onTimeoutWarning.subscribe((countdown) => {
            this.idleTimeoutWarningCount++;
            // console.log('Vous allez être déconnecté dans ' + countdown + ' secondes !!');
          });

          this.reset();
        }
      });
  }

  menus: any;
  client: Client;
  noCms = false;

  clientStandard = true;

  menuSubscription: Subscription;
  loginSubscription: Subscription;
  tanSubscription: Subscription;

  private subscription: ISubscription;
  private subscription1: ISubscription;

  selectedIdx = -1;
  selectedIdxC = 2;

  activeLink = "";

  showLoginButton = false;
  clientConseil = null;
  clientConseilGSM = null;

  private timedOut = false;
  emergencyMode = "";

  private idleEndCount = 0;
  private idleStartCount = 0;
  private idleTimeoutCount = 0;
  private idleTimeoutWarningCount = 0;

  private inactivityTime = 300; // 5mn = 300s : paramétre par défaut abaxx
  private timeOut = 1800; // 30mn = 1800s : paramétre par défaut abaxx

  deviceInfo = null;

  // Déconnexion à la fermeture de l'onglet, reload
  @HostListener("window:unload", ["$event"])
  unloadHandler(event) {
    if (environment.production) {
      // Ne pas se déconnecter au débranchement CAT, car possibilité de retour au site copartis depuis CAT
      if (!this.router.url.match("/cat/bourse")) {
        this.doLogout();
      }
    }
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  ngOnDestroy() {
    this.clearData();
    this.idle.stop();
    this.subscription.unsubscribe();
    this.subscription1.unsubscribe();
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.subscription1 = this.authService.isLoggedIn().subscribe((data) => {
      this.client.isLoggedIn = data;
      if (this.client.isLoggedIn) {
        // Pour prévenir les appels MW au retour CAT
        if (this.router.url === "/" && location.pathname === "/logout") {
          return;
        }
        const userId = this.storage.getUserId();
        this.logger.debug("header userId : " + userId);
        this.clientConseil = false;
        this.clientConseilGSM = false;
        this.client.restrictedArea = false;
        this.mwService
          .getUserAccountsValoMif2(userId)
          .pipe(take(1))
          .subscribe((dataA) => {
            const comptes = dataA.accountsValo.accounts;
            if (comptes) {
              const comptesNoVisual = comptes.filter(function (compte) {
                return !compte.roles.find(function (roleElt) {
                  return roleElt.name === "fr.privilege.visual";
                });
              });
              if (comptesNoVisual.length < 1) {
                this.clientConseil = true;
                this.clientConseilGSM = true;
              } else {
                const comptesNoVisualGSM = comptesNoVisual.filter(function (
                  compte
                ) {
                  return !compte.roles.find(function (roleElt) {
                    return roleElt.name === "fr.privilege.gsm";
                  });
                });
                if (comptesNoVisualGSM.length < 1) {
                  this.clientConseilGSM = true;
                }
              }
            } else {
              this.clientConseil = true;
              this.clientConseilGSM = true;
            }
            this.subscription = this.mwService
              .getUserHeaderInfo(userId)
              .pipe(take(1))
              .subscribe(
                (data) => {
                  this.client.restrictedArea = data.restrictedArea;
                  this.authService.changeRestrictedMode(
                    this.client.restrictedArea
                  );

                  this.client.lastLoginDate = data.loginInfo.lastLoginDate;
                  this.client.lastLoginTime = data.loginInfo.lastLoginTime;
                  this.client.remainingDays = data.mdpRemainingDays;
                  this.client.roleResultant = data.roleResultant;

                  this.clientStandard = true;
                  if (
                    this.client.roleResultant !== environment.roleResultantRef
                  ) {
                    this.clientStandard = false;
                  }
                  if (this.client.restrictedArea) {
                    if (data.restrictedAreaCode === "101") {
                      // Redirection vers la changement de mot de passe qui a expiré
                      this.router.navigate(["/profil/mdp"]);
                    }
                  } else {
                    // Alerte que pour les client standard non géré QF PP
                    if (this.clientStandard && !this.clientConseilGSM) {
                      this.mwService
                        .getUserAllMif2(userId)
                        .pipe(take(1))
                        .subscribe(
                          (dataAll) => {
                            if (dataAll.lastMif2QuestStatus !== "0") {
                              this.goToQuiz();
                            } else {
                              if (dataAll.lastMif2QuestDate) {
                                const dateRe = new Date("2023-07-27");
                                if (
                                  Date.parse(dataAll.lastMif2QuestDate) <
                                  dateRe.getTime()
                                ) {
                                  this.goToQuiz();
                                } else {
                                  this.client.restrictedArea = false;
                                  this.authService.changeRestrictedMode(
                                    this.client.restrictedArea
                                  );
                                }
                              } else {
                                this.goToQuiz();
                              }
                            }
                          },
                          (error) => {
                            this.mwService.handleError("allMif2", error);
                          }
                        );
                    }
                    // Affichage du Nom et prénom CRM
                    this.getName(userId);
                    this.getSecuriteMobile(userId);
                    this.getSessionMobile(userId);
                  }
                },
                (error) => {
                  this.mwService.handleError("HeaderInfo", error);
                }
              );
            this.refreshMenu();

            this.tanService.currentValueMtan.subscribe(
              (value) => (this.client.mTan = value)
            );
            this.tanService.currentValueStan.subscribe(
              (value) => (this.client.sessionTanStatus = value)
            );
            this.alink.currentActiveLink.subscribe(
              (value) => (this.activeLink = value)
            );
          });
      }
    });

    this.showLoginButton = true;
    if (
      this.router.url.match("/") ||
      this.router.url.match("/home") ||
      this.router.url.match("/logout")
    ) {
      this.showLoginButton = false;
    }
  }
  goToLogin() {
    this.router.navigateByUrl("/login");
    console.log("******");
  }
  goToQuiz() {
    this.client.restrictedArea = true;
    this.authService.changeRestrictedMode(this.client.restrictedArea);
    this.router.navigate(["/profil/mif2/qfin"]);
  }
  getName(userId) {
    this.mwService.getUserHeaderNameInfo(userId).subscribe(
      (data) => {
        if (data.errorCode === "0") {
          this.client.fullName = data.fullName;
        } else {
          this.client.fullName = data.errorDesc;
        }
      },
      (error) => {
        this.client.fullName = "";
        this.mwService.handleError("HeaderNameInfo", error);
      }
    );
  }

  getSecuriteMobile(userId) {
    /*this.subscription = this.mwService.TanPhone().subscribe(
      tanData => {
        this.client.mTan = tanData.authStatus;
        this.tanService.changeValueMtan(this.client.mTan);
      });*/
    this.subscription = this.mwService.TanPhone2().subscribe((tanData) => {
      this.client.mTan = tanData.status;
      this.tanService.changeValueMtan(this.client.mTan);
    });
  }

  getSessionMobile(userId) {
    this.mwService.getSessionTanStatus2().subscribe((SessionTan) => {
      this.client.sessionTanStatus = SessionTan.sessionTanActive ? "A" : "D";
      this.tanService.changeValueStan(this.client.sessionTanStatus);
    });
    /*this.mwService.getSessionTanStatus().subscribe(
      SessionTan => {
        this.client.sessionTanStatus = SessionTan.sessionTanStatus;
        this.tanService.changeValueStan(this.client.sessionTanStatus);
      });*/
  }

  open() {
    this.modalService.open(LoginComponent);
  }

  doLogout() {
    window.scrollTo(0, 0);
    this.clearData();
    this.tanService.changeValueStan("D");
    this.idle.stop();
    this.idle.ngOnDestroy();
    this.router.navigate(["/home"]);
    this.storage.signOut();
  }

  catBourseRedirect() {
    window.scrollTo(0, 0);
    /*this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();*/
    this.router.navigate(["/cat"]);
  }

  clearData() {
    this.client = new Client();
    this.tanService.changeValueMtan("");
    this.selectedIdx = -1;
    this.selectedIdxC = 2;
  }

  private refreshMenu() {
    // TODO passer le siteID
    window.scrollTo(0, 0);
    this.menuSubscription = this.cmsService.getMenuTree().subscribe(
      (menuData) => {
        const status = menuData.cmsStatus;
        if (status === "CMS_DOWN") {
          this.noCms = true;
          return;
        }
        if (status === "CMS_UP") {
          this.menus = menuData.result;
          this.menus.sort((a, b) =>
            parseInt(a.link.weight) > parseInt(b.link.weight) ? 1 : -1
          );
          this.noCms = false;
          for (let i = 0; i < this.menus.length; i++) {
            if (this.menus[i].subtree != null) {
              this.menus[i].subtree.sort((a, b) =>
                parseInt(a.link.weight) > parseInt(b.link.weight) ? 1 : -1
              );
            }
          }
        }
      },
      (menuError) => {
        this.noCms = true;
        this.logger.info("CMS KO menuTree   : " + JSON.stringify(menuError));
        //this.mwService.handleError('menuTree', menuError);
      }
    );
  }

  getContent(id) {
    id = id.split("/").pop();
    this.router.navigate(["/cms"], { queryParams: { i: id } });
  }

  selectItem(index): void {
    window.scrollTo(0, 0);
    this.selectedIdx = index;
  }

  selectItemC(index): void {
    window.scrollTo(0, 0);
    this.selectedIdxC = index;
  }
}
