import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable()
export class TanService {
  // Session Tan
  private valueSourceStan = new BehaviorSubject("D");
  currentValueStan = this.valueSourceStan.asObservable();

  // MobileTan
  private valueSourceMtan = new BehaviorSubject("");
  currentValueMtan = this.valueSourceMtan.asObservable();

  constructor() {}

  changeValueStan(value: string) {
    this.valueSourceStan.next(value);
  }

  changeValueMtan(value: string) {
    this.valueSourceMtan.next(value);
  }
}
