export class Client {
  fullName = "";
  lastLoginDate = "";
  lastLoginTime = "";
  mTan = "";
  sessionTanStatus = "";
  mtanBlacklistStatus = "";
  remainingDays = "";
  isLoggedIn = false;
  restrictedArea;
  restrictedAreaCode = "";
  displayAlert = false;
  roleResultant = "";
  accesCAT = false;
  roles = [];
}

export class BaseModel {
  errorCode: string;
  errorDesc: string;
  errorOrigin: string;
  errorSeverity: string;
}

export class AlertModel {
  nbAlerts = 0;
  showMotDePasseAlert = false;
  mdpRemainingDays = 0;
  showMif2FinAlert = null;
  showMif2RskAlert = false;
  showPMMif2FinAlert = false;
  showMobileTanAlert = false;
  //how mant alerts?
  //showMif2RskAssAlert = false;
  tanStatus = "";
}
