import { Component, OnInit } from '@angular/core';
import { MwService } from '../../../../shared/services/mw/mw.service';
import { GenericService } from '../../../../shared/services/generic/generic.service';
import { AuthService } from '../../../../shared/services/auth/auth.service';
import { StorageService } from '../../../../shared/services/auth/storage.service';
import { environment } from '../../../../../environments/wla/environment';
import { Title } from '@angular/platform-browser';
import { AlinkService } from '../../../../shared/services/alink/alink.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'logout-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  titlePage = 'Déconnexion';

  idPageHeader = 0;
  idPageFooter = 0;
  pinChange = false;

  constructor(private alink: AlinkService, public router: Router, private route: ActivatedRoute, private titleService: Title, private mwService: MwService, private genericService: GenericService, private authService: AuthService, private storage: StorageService) { }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.route.params.subscribe(params => {
      this.pinChange = params['p'];
    });

    this.titleService.setTitle(this.titlePage);
    this.alink.changeActiveLink(this.router.url.split(';')[0]);
    this.alink.changeLoginExpandedStatus(true);
    this.idPageHeader = environment.cms_id_logout_header;
    this.idPageFooter = environment.cms_id_logout_footer;
    const userId = this.storage.getUserId();

    if (userId) {
      this.genericService.doLogout().subscribe(
        data => {
        },
        error => {
          this.mwService.handleError('Logout', error);
        });
    }
    this.authService.logout();

  }

}
