import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CmsService } from '../../../shared/services/cms/cms.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsModel } from '../../../shared/services/cms/models/cms.model';
import { AlinkService } from '../../../shared/services/alink/alink.service';

@Component({
  selector: 'cms-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.css']
})
export class CmsComponent implements OnInit {
  cmsModel: CmsModel;

  @Input() id;
  @Input() isClient;
  constructor(private cmsService: CmsService, private alink: AlinkService, private route: ActivatedRoute, public router: Router) { }


  ngOnInit() {
    this.alink.changeActiveLink(this.router.url.split(';')[0]);
    this.cmsModel = new CmsModel();

    // Lorsqu'un composant charge un bloc CMS (header, footer, etc ...)
    if (this.id) {
      this.cmsModel = this.cmsService.getBlocCms(this.id, this.isClient);
      //console.log("isclient", this.isClient)
      return;
    }
    // Lorsqu'on redirige vers une page CMS
    this.route.params.subscribe(params => {
      const id = params['i'];
      if (!id) {
        return;
      }
      this.cmsModel = this.cmsService.getBlocCms(id, true);
    });
    console.log("cmsModel", this.cmsModel)
  }


  // Lorsqu'on clique sur un lien dans un contenu purement CMS
  @HostListener('click', ['$event'])
  public onClick(event) {

    // Lien externe
    if (event.class === 'extern') {
      return;
    }



    // Lien interne
    if (event.target.tagName === 'A') {
      this.alink.changeLoginExpandedStatus(false);
      const href = event.target.getAttribute('href');

      if (href && href != "undefined") {
        const servicePart = href.split('?')[0];
        const paramPart = href.split('?')[1];

        const paramList = paramPart.split('&');
        const params = {};
        for (let i = 0; i < paramList.length; i++) {
          const propName = paramList[i].split('=')[0];
          const propValue = paramList[i].split('=')[1];
          params[propName] = propValue;
        }

        this.router.navigate([servicePart, params]);
         event.preventDefault();
      }
    } else {
      return;
    }
  }


}
