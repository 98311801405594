import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/shr/environment';
import { Constants } from './constants/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  constructor(protected httpClient: HttpClient) { }

  public doLogin(userId, nip, siteId) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(environment.genericAuthUrl + Constants.SERVICE_AUTH,
      { 'username': userId, 'password': nip, 'siteId': siteId }, { headers: headers, observe: 'response' });
  }

  public doLogout() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(environment.genericAuthUrl + Constants.SERVICE_LOGOUT,null,{ headers: headers, observe: 'response' });
  }

  // Services de mises à jour
  public changerMdp(id, pin, newPin) : Observable<any>  {
    const queryParams = `?pin=`+pin+`&pinNew=`+newPin;
    return this.httpClient.post<any>(environment.genericAuthUrl + Constants.SERVICE_PIN_CHANGE + queryParams, '');
  }

  public passwordRecovery(mmid, body) {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.httpClient.post<any>(environment.genericAuthUrl + Constants.PASSWORD_RESCUE + '/RB/' + mmid, JSON.stringify(body), {
      headers,
    });
  }

  /*MIF2: Lien entre une personne et la PM*/
  public rlsLinks() {
    return this.httpClient.get<any>(environment.genericUrl + Constants.SERVICE_RLS);
  }

  public clientDatas(companyCode: any, personId: any, referenceClient: any) {
    return this.httpClient.get<any>(environment.genericUrl + Constants.SERVICE_CRM_GET_CLIENT_DATA, {
      params: {
        companyCode: companyCode,
        referenceClient: referenceClient,
        personId: personId
      }
    });
  }

  public getMIF2Answers(origin: any, referenceClient: any, type: any) {
    return this.httpClient.get<any>(environment.genericUrl + Constants.SERVICE_CRM_GetMIF2Answers, {
      params: {
        type: type,
        referenceClient: referenceClient,
        origin: origin
      }
    });
  }

  public setMIF2Answers(json) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.httpClient.post<any>(
      environment.genericUrl + Constants.SERVICE_CRM_GetMIF2Answers,
      JSON.stringify(json), httpOptions);
  }

  public mif2ValidationV2(json) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.httpClient.post<any>(
      environment.genericUrl + Constants.SERVICE_CRM_MIF2VALIDATION_V2,
      JSON.stringify(json), httpOptions);
  }

  // Mobile TAN generic-api
   public registerPhone2(json) {
     const httpOptions = {
       headers: new HttpHeaders({ 'Content-Type': 'application/json' })
     };
     return this.httpClient.post<any>(
       environment.genericUrl + Constants.SERVICE_REGISTER_PHONE2,
       JSON.stringify(json), httpOptions);
   }
   public TanPhone2() {
     return this.httpClient.get<any>(environment.genericUrl + Constants.SERVICE_TAN_PHONE2);
   }
   //TODO: same service
   public getSessionTanStatus2() {
     return this.httpClient.get<any>(environment.genericUrl + Constants.SERVICE_SESSION_TAN_STATUS2);
   }
   public activateTan2(tan) {
     return this.httpClient.post<any>(environment.genericUrl + Constants.SERVICE_ACTIVATE_TAN2 + `/${tan}`, '');
   }
   public deactivateTan2() {
     return this.httpClient.post<any>(environment.genericUrl + Constants.SERVICE_DESACTIVATE_TAN2, '');
   }
   public registerTan2(tan) {
     return this.httpClient.post<any>(environment.genericUrl + Constants.SERVICE_REGISTER_TAN2 + `/${tan}`, '');
   }


  public requestTan2(svcName, retransmit, svcSignature) {
    let body;
    body = {
      serviceName: svcName,
      retransmit: retransmit,
      serviceSignature: svcSignature
    };
    return this.httpClient.post<any>(environment.genericUrl + Constants.SERVICE_REQUEST_TAN2, body);
  }
  public activateSessionTan2(tan) {
    return this.httpClient.post<any>(environment.genericUrl + Constants.SERVICE_SESSION_TAN_ACTIVATE2+ `/${tan}`, '');
  }
  public deactivateSessionTan2() {
    return this.httpClient.post<any>(environment.genericUrl + Constants.SERVICE_SESSION_TAN_DESACTIVATE2, null);
  }
  public updateSessionTanAtLoginActivate() {
    //const formData: FormData = new FormData();
    //formData.append('status', status + '');
    return this.httpClient.post<any>(environment.genericUrl + Constants.SERVICE_SESSION_TAN_ATLOGIN2_ACTIVATE, null);
  }
  public updateSessionTanAtLoginDeactivate() {
    return this.httpClient.post<any>(environment.genericUrl + Constants.SERVICE_SESSION_TAN_ATLOGIN2_DEACTIVATE, null);
  }

  /** Captcha */
  setTokenCaptcha(token,compagny) {
      console.log("token",token)
    const headers = new HttpHeaders({
        apiKey: environment.apiKey,
        application: environment.application,
        'token': token
    });
    const queryParams = `?compagny=`+compagny;
    return this.httpClient.post<any>(
        environment.genericPubUrl + Constants.SERVICE_CAPTCHA + queryParams, '',
        { headers }
    );
  }


}
