import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from './constants/cms.constants';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/shr/environment';
import { CmsModel } from './models/cms.model';
import { DomSanitizer } from '@angular/platform-browser';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class CmsService {

  constructor(protected httpClient: HttpClient, public router: Router, private sanitizer: DomSanitizer, private logger: NGXLogger, private authService: AuthService, ) { }

  private getContent(id) {
    return this.getHttpResponseId(Constants.METHOD_CONTENT, id);
  }

  private getContentSec(id) {
    return this.getHttpResponseId(Constants.METHOD_CONTENT_SEC, id);
  }

  public getMenuTree() {
    return this.getHttpResponse(Constants.METHOD_MENU_TREE);
  }

  public getWebConferencesList() {
    return this.getHttpResponse(Constants.METHOD_WEB_CONFERENCES_LIST);
  }

  public getVideosBourseList() {
    return this.getHttpResponse(Constants.METHOD_VIDEOS_BOURSE_LIST);
  }
  public getHttpResponseId(method, id): Observable<any> {
    return this.httpClient.get<any>(environment.cmsUrl + method + `/${id}`);
  }

  public rciPCStatistiques() {
    return this.getHttpResponse(Constants.METHOD_RCI_CONSEILLER_STATISTIQUES);
  }

  public rciPCMetrique(id) {
    return this.getHttpResponseId(Constants.METHOD_RCI_CONSEILLER_METRIQUE, id);
  }

  public rciPcIncident() {
    return this.getHttpResponse(Constants.METHOD_RCI_CONSEILLER_MONITORING);
  }

  public psaPCStatistiques() {
    return this.getHttpResponse(Constants.METHOD_PSA_CONSEILLER_STATISTIQUES);
  }

  public psaPCMetrique(id) {
    return this.getHttpResponseId(Constants.METHOD_PSA_CONSEILLER_METRIQUE, id);
  }

  public psaPcIncident() {
    return this.getHttpResponse(Constants.METHOD_PSA_CONSEILLER_MONITORING);
  }

  public getHttpResponse(method): Observable<any> {
     return this.httpClient.get<any>(environment.cmsUrl + method);
  }


  // A remplacer par error interceptor, Idem pour services MW
  public handleError(service, error) {
    if (error.status === 401) {
      // localStorage.clear();
      this.router.navigate(['/home']);
    }
  }

  public getBlocCms(idPage, isClient): CmsModel {
    const cmsModel = new CmsModel();

    if (isClient) {
      this.getContentSec(idPage).subscribe(
        nodeData => {
          const status = nodeData.cmsStatus;
          if (status === 'CMS_DOWN') {
            cmsModel.cmsDown = true;
            return;
          }
          if (nodeData.body) {
            // Sanitizes a value for use in the given SecurityContext.
            // cmsModel.body = this.sanitizer.sanitize(SecurityContext.HTML, nodeData.body[0].value);
            const node = nodeData.body[0];
            if (node) {
              cmsModel.body = this.sanitizer.bypassSecurityTrustHtml(node.value);
            }
          }
          if (nodeData.title) {
            cmsModel.title = nodeData.title[0].value;
          }
        },
        nodeError => {
          cmsModel.cmsDown = true;
          this.logger.info('CMS nodeError  : ' + idPage + ' - ' + JSON.stringify(nodeError));
        });

      return cmsModel;

    } else {

      this.getContent(idPage).subscribe(
        nodeData => {
          const status = nodeData.cmsStatus;
          if (status === 'CMS_DOWN') {
            cmsModel.cmsDown = true;
            return;
          }
          if (nodeData.body) {
            // Sanitizes a value for use in the given SecurityContext.
            // cmsModel.body = this.sanitizer.sanitize(SecurityContext.HTML, nodeData.body[0].value);
            const node = nodeData.body[0];
            if (node) {
              cmsModel.body = this.sanitizer.bypassSecurityTrustHtml(node.value);
            }
          }
          if (nodeData.title) {
            cmsModel.title = nodeData.title[0].value;
          }
        },
        nodeError => {
          cmsModel.cmsDown = true;
          this.logger.info('CMS nodeError  : ' + idPage + ' - ' + JSON.stringify(nodeError));
        });

      return cmsModel;
    }

  }

  public getReleaseNotesList() {
    return this.getHttpResponse(Constants.METHOD_RELEASE_NOTES_LIST);
  }

  public getPsaCampagnes() {
    return this.getHttpResponse(Constants.METHOD_PSA_CAMPAGNES);
  }

  //ublic getPsaCampagnesParrainage() {
    //  return this.getHttpResponse(Constants.METHOD_PSA_CAMPAGNES_PARRAINAGE);
  //}

  // RCI CMS Services

  public getRciPcEnteteAlerteVisiteur() {
    return this.getHttpResponse(Constants.METHOD_RCI_PUBLIC_HEADER);
  }

  public getRciPcEnteteAlerteClient() {
    return this.getHttpResponse(Constants.METHOD_RCI_PRIVATE_HEADER);
  }

  public getRciPcCookies() {
    return this.getHttpResponse(Constants.METHOD_RCI_COOKIES);
  }

  public getRciPcCampagnes() {
    return this.getHttpResponse(Constants.METHOD_RCI_CAMPAGNES);
  }

  public getRciPcReleaseNotes() {
    return this.getHttpResponse(Constants.METHOD_RCI_RELEASE_NOTES);
  }


}
