import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { Subscription } from "rxjs";
import { environment } from "../../../../../environments/wla/environment";
import { AlinkService } from "../../../../shared/services/alink/alink.service";
import { AuthService } from "../../../../shared/services/auth/auth.service";
import { StorageService } from "../../../../shared/services/auth/storage.service";
import { Constants } from "../../../../shared/services/mw/constants/constants";
import { BaseModel } from "../../../../shared/services/mw/models/client.model";
import { MwService } from "../../../../shared/services/mw/mw.service";

@Component({
  selector: "login-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input() userId = "";
  @Input() nip = "";
  @Input() memoriserIdentifiant = false;

  error = false;
  message: BaseModel;
  errorAlert: String = "";
  showSpinner = false;
  loginReq: Subscription;
  loggedIn = true;
  expanded = true;
  hide1: boolean = true;
  private restrictedArea: boolean;

  constructor(
    private alink: AlinkService,
    private mwService: MwService,
    private authService: AuthService,
    public router: Router,
    private storage: StorageService,
    private logger: NGXLogger
  ) {}

  ngOnInit() {

    window.scrollTo(0, 0);

    this.alink.currentLoginExpandedStatus.subscribe(
      (status) => (this.expanded = status)
    );
    this.authService.isLoggedIn().subscribe((v) => {
      this.loggedIn = v;
      this.showSpinner = false;
      this.error = false;
      if (this.storage.getUserIdMem()) {
        this.memoriserIdentifiant = true;
        this.userId = this.storage.getUserIdMem();
      }
    });
    this.alink.currentUserIdStatus.subscribe((username) => {
      if (username) {
        this.userId = username;
      }
    });
  }

  togglePanel(expanded) {
    expanded = !expanded;
  }

  doLogin() {
    window.scrollTo(0, 0);
    if (this.userId === "" || this.nip === "") {
      this.error = true;
      this.errorAlert = Constants.LOGIN_Field_required;
      return;
    }

    this.showSpinner = true;
    this.loginReq = this.authService
      .doLogin(this.userId, this.nip, environment.siteId)
      .subscribe(
        (reponse) => {
          const token = reponse.headers.get("authorization");
          this.storage.saveToken(token);
          this.storage.saveUserId(this.userId);
          // Memorize userId
          if (this.memoriserIdentifiant) {
            this.storage.saveUserIdMem(this.userId);
          } else {
            this.storage.removeUserIdMem();
          }
          this.restrictedArea = reponse.body.firstAccess;
          if (this.restrictedArea) {
            this.router.navigate(['/profil/mdp']);
          } else {
            this.router.navigate(['/synthese/compte']);
          }
          this.userId = '';
          this.nip = '';
        },
        (error) => {
          this.error = true;
          this.showSpinner = false;
          this.message = this.mwService.handleError("login", error);
          this.errorAlert = error.error.message;
        },
        () => {
          this.authService.isLoggedIn().subscribe((result) => {
            if (result) {
              this.router.navigate(["/synthese/compte"]);
              this.userId = "";
              this.nip = "";
            }
          });
        }
      );
  }

  ngOnDestroy() {
    // this.loginReq.unsubscribe();
  }
}
