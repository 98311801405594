import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'notresolution-notre-solution',
  templateUrl: './notre-solution.component.html',
  styleUrls: ['./notre-solution.component.css']
})
export class NotreSolutionComponent implements OnInit {

  constructor() { }

  showCol1 = false;
  showCol2 = false;

  ngOnInit() {
    window.scroll(0, 0);

  }

  col1Show() {
    this.showCol1 = true;
    this.showCol2 = false;
  }

  col1Hide() {
    this.showCol1 = false;
    this.showCol2 = false;
  }


  col2Show() {
    this.showCol2 = true;
    this.showCol1 = false;
  }

  col2Hide() {
    this.showCol1 = false;
    this.showCol2 = false;
  }


}
