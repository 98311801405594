import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaskPipe } from '../../shared/pipes/mask.pipe';
import { SpinnerComponent } from './spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { CleanPipe } from '../../shared/pipes/clean.pipe';
import { MatDialogModule, MatButtonModule, MatFormFieldModule } from '@angular/material';
import { MdDialogsHelperModule } from './md-dialogs-helper/md-dialogs-helper.module';
import { InfoComponent } from './info/info.component';
import { CmsComponent } from '../pages/cms/cms.component';
import { EditoModule } from '../pages/edito/edito.module';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgxSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MdDialogsHelperModule,
    MatPasswordStrengthModule.forRoot(),
    NgbModule.forRoot()
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    InfoComponent,
    SpinnerComponent,
    CmsComponent,
    MaskPipe,
    EditoModule
  ],
  declarations: [HeaderComponent, FooterComponent, MaskPipe, CleanPipe, SpinnerComponent, InfoComponent, CmsComponent],
  entryComponents: [

  ]

})
export class SharedModule { }


