import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nosvaleurs-nos-valeurs',
  templateUrl: './nos-valeurs.component.html',
  styleUrls: ['./nos-valeurs.component.css']
})
export class NosValeursComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0, 0);

  }

}
