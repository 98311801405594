import { Routes } from '@angular/router';
import { AuthGuardService } from '../../../shared/services/auth/auth-guard.service';
import { CmsComponent } from '../cms/cms.component';
import { HomeComponent } from '../public/home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { MdpoubliComponent } from './mdpoubli/mdpoubli.component';


export const routes: Routes = [
    { path: 'profil', loadChildren: './profil/profil.module#ProfilModule', canActivate: [AuthGuardService]  },
    { path: 'cat', loadChildren: '../cat/cat.module#CatModule', canActivate: [AuthGuardService]  },
    { path: 'synthese', loadChildren: './synthese/synthese.module#SyntheseModule', canActivate: [AuthGuardService]  },
    { path: 'tan', loadChildren: './tan/tan.module#TanModule', canActivate: [AuthGuardService]  },
    { path: 'gestion', loadChildren: './gestion/gestion.module#GestionModule', canActivate: [AuthGuardService]  },
    { path: 'edito', loadChildren: '../edito/edito.module#EditoModule'},
    { path: 'logout', component: LogoutComponent},
     { path: 'cms', component: CmsComponent},
    { path: 'mdpoubli', component: MdpoubliComponent},
    // { path: '**', redirectTo: 'synthese', canActivate: [AuthGuardService] }
];
