// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  siteId: "cpp",
  siteIdMob: "cpm",
  feId: "WEB",
  feIdMob: "MOBILE",
  roleResultantRef: "fr.cust.standard.resultant",
  recaptchaSiteKey: "6LfxTFInAAAAAJPwESdJ0I5vde6rgKAqq0Rx7Zfv",
  apiKey: "634159cf-83ef-4652-bdaf-a04d8fda64b2",
  application: "wlaDev",

  cms_id_home: 9,

  cms_id_home_header: 47,
  cms_id_home_footer: 48,

  cms_id_logout_header: 47,
  cms_id_logout_footer: 48,

  cms_id_footer: 42,

  cms_id_info: 46,

  cms_id_SyntheseComptes_header: 45,
  cms_id_SyntheseComptes_footer: 10000,
  cms_id_alertAction: 10000,

  cms_id_SyntheseComptesDetail_header: 45,
  cms_id_SyntheseComptesDetail_footer: 10000,

  cms_id_SyntheseAssuranceDetail_header: 10000,
  cms_id_SyntheseAssuranceDetail_footer: 10000,

  cms_id_MobileTan_header: 10000,
  cms_id_MobileTan_footer: 10000,

  cms_id_SessionTan_header: 10000,
  cms_id_SessionTan_footer: 10000,

  cms_id_SessionTan_desactive_header: 10000,
  cms_id_SessionTan_desactive_footer: 10000,

  cms_id_MIF2_header: 10000,
  cms_id_MIF2_footer: 10000,

  cms_id_MesCoordonnees_header: 10000,
  cms_id_MesCoordonnees_footer: 10000,

  cms_id_ChangementMotPasse_header: 10000,
  cms_id_ChangementMotPasse_footer: 10000,

  cms_id_Releves_header: 10000,
  cms_id_Releves_footer: 10000,

  cms_id_AjoutIban_header: 10000,
  cms_id_AjoutIban_footer: 10000,

  cms_id_ImpressionRIB_header: 10000,
  cms_id_ImpressionRIB_footer: 10000,

  cms_id_RemiseDeCheque_header: 10000,
  cms_id_RemiseDeCheque_footer: 10000,

  cms_id_MotPasseOublie_header: 10000,
  cms_id_MotPasseOublie_footer: 10000,

  cms_id_MIF2_QFIN_header: 10000,
  cms_id_MIF2_QFIN_footer: 10000,

  cms_id_MIF2_QRSK_header: 10000,
  cms_id_MIF2_QRSK_footer: 10000,
};
