import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
export class dialogData {
  message: string;
  title: string;
}



@Component({
  selector: 'app-dialog-notification-home',
  templateUrl: './dialog-notification-home.component.html',
  styleUrls: ['./dialog-notification-home.component.css']
})
export class DialogNotificationHomeComponent  {

  info: dialogData;
  constructor(
    public dialogRef: MatDialogRef<DialogNotificationHomeComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
     this.info = data;
     }

  onYesClick(): void {
    this.dialogRef.close(true);
  }



}
