import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable({
  providedIn: "root",
})
export class AlinkService {
  private activeLink = new BehaviorSubject("/synthese");
  currentActiveLink = this.activeLink.asObservable();

  private loginExpanded = new BehaviorSubject<boolean>(false);
  currentLoginExpandedStatus = this.loginExpanded.asObservable();
  private userId = new BehaviorSubject<string>(null);
  currentUserIdStatus = this.userId.asObservable();

  changeActiveLink(value: string) {
    this.activeLink.next(value);
  }

  changeLoginExpandedStatus(value: boolean) {
    this.loginExpanded.next(value);
  }

  changeUserIdStatus(value: string) {
    this.userId.next(value);
  }
}
