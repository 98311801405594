import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef , MatDialogConfig} from '@angular/material';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class MdDialogsHelperComponent {
  public defaultWidth = '30vw';

  constructor(private dialogs: MatDialog) {}

  public confirm(title: string = 'Confirm Action', message: string = 'Are you sure?'): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmDialogComponent>;
    const config = new MatDialogConfig();

    dialogRef = this.dialogs.open(ConfirmDialogComponent, { width: this.defaultWidth, height: '30%' , panelClass: 'myClass' });

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;

    return dialogRef.afterClosed().pipe(map((res) => !!res)); // always return a boolean value

  }
}
