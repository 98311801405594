export class Constants {
    public static METHOD_MENU_TREE = 'menuTree';
    public static METHOD_MENU_ITEM = 'menuItems';
    public static METHOD_CONTENT = 'content';
    public static METHOD_CONTENT_SEC = 'contentSec';
    public static METHOD_WEB_CONFERENCES_LIST = 'webConferencesList';
    public static METHOD_VIDEOS_BOURSE_LIST = 'videosBourseList';
    public static METHOD_RELEASE_NOTES_LIST = 'releaseNotesList';
    public static METHOD_PSA_CAMPAGNES = 'psaCampagnes';
    public static METHOD_RCI_PUBLIC_HEADER = 'rciPcEnteteAlerteVisiteur';
    public static METHOD_RCI_PRIVATE_HEADER = 'rciPcEnteteAlerteClient';
    public static METHOD_RCI_COOKIES = 'rciPcPageCookie';
    public static METHOD_RCI_CAMPAGNES = 'rciPcCampagnes';
    public static METHOD_RCI_RELEASE_NOTES = 'rciPcReleaseNotes';
    public static METHOD_RCI_CONSEILLER_STATISTIQUES = 'rciPCStatistiques';
    public static METHOD_RCI_CONSEILLER_METRIQUE = 'rciPCMetrique';
    public static METHOD_RCI_CONSEILLER_MONITORING = 'rciPcIncident';
    public static METHOD_PSA_CONSEILLER_STATISTIQUES = 'psaPCStatistiques';
    public static METHOD_PSA_CONSEILLER_METRIQUE = 'psaPCMetrique';
    public static METHOD_PSA_CONSEILLER_MONITORING = 'psaPcIncident';
}

