import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { environment } from "../../../../../environments/wla/environment";
import { AlinkService } from "../../../../shared/services/alink/alink.service";
import { MdDialogsHelperComponent } from "../../../shared/md-dialogs-helper/md-dialogs-helper.component";
import { DialogNotificationHomeComponent } from "./dialog-notification-home/dialog-notification-home.component";
@Component({
  selector: "home-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  idPageHeader = 0;
  idPageFooter = 0;

  constructor(
    private alink: AlinkService,
    private dialogs: MdDialogsHelperComponent,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.openDialog();
    this.alink.changeLoginExpandedStatus(true);
    this.idPageHeader = environment.cms_id_home_header;
    this.idPageFooter = environment.cms_id_home_footer;
  }

  openDialog() {
    const message =
      "Attention, nous vous informons que des personnes mal intentionnées se font actuellement passer pour Copartis.<br>" +
      "Copartis n'offre aucun service en direct auprès d'une clientèle de particulier, que ce soit dans le domaine des " +
      "livrets rémunérés ou dans le domaine des titres.<br>" +
      "Toute personne vous proposant ce type d'offre au nom de Copartis le fait en usurpant notre identité.<br>" +
      "Vous devez être très vigilants. <b>Les sites « investissement-locatif-2023.com » et « france-neo-livret.com » " +
      "sont de faux sites</b> qui usurpent l'identité de Copartis. ";
    const title =
      "SOYEZ EXTREMEMENT VIGILANTS – Des fraudeurs se font passer pour Copartis, via de fausses offres livret !";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message: message,
      title: title,
    };
    const dialogRef = this.dialog.open(
      DialogNotificationHomeComponent,
      dialogConfig
    );
  }
}
