import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EditoRoutingModule } from './edito-routing.module';
import { EditoComponent } from './edito.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared/shared.module';
import { NousDecouvrirComponent } from './nous-decouvrir/nous-decouvrir.component';
import { NotreSolutionComponent } from './notre-solution/notre-solution.component';
import { NosValeursComponent } from './nos-valeurs/nos-valeurs.component';
import { NousContacterComponent } from './nous-contacter/nous-contacter.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';
import { PolitiqueDeConfidentialiteComponent } from './politique-de-confidentialite/politique-de-confidentialite.component';
import { InformationsReglementairesComponent } from './informations-reglementaires/informations-reglementaires.component';
import { CookiesComponent } from './cookies/cookies.component';
import { EspaceDonneesPersonnellesComponent } from './espace-donnees-personnelles/espace-donnees-personnelles.component';

@NgModule({
  imports: [
    CommonModule,
    EditoRoutingModule,
    NgbModule.forRoot(),
    ],
  declarations: [EditoComponent, NousDecouvrirComponent, NotreSolutionComponent, NosValeursComponent, NousContacterComponent, MentionsLegalesComponent, PolitiqueDeConfidentialiteComponent, InformationsReglementairesComponent, CookiesComponent, EspaceDonneesPersonnellesComponent]
})
export class EditoModule { }
