import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mask'
})
export class MaskPipe implements PipeTransform {

    // transform(value: string, firstMaskedChar: number, nbMaskedChars: number, showMask: boolean): string {
    //     if (!showMask || value.length < nbMaskedChars || nbMaskedChars < firstMaskedChar) {
    //         return value;
    //     }
    //     let leftVisibleSection = value.slice(0, firstMaskedChar - 1);
    //     let maskedSection = value.slice(firstMaskedChar, firstMaskedChar + nbMaskedChars);
    //     let rightVisibleSection = value.slice(firstMaskedChar -1 + nbMaskedChars);
    //     return leftVisibleSection + maskedSection.replace(/./g, '*') + rightVisibleSection;
    // }

    transform(value: string, type: string, showMask: boolean): string {
        if (!showMask || !value || value.length < 1) {
            return value;
        }

        if (type === 'email') {
            const leftValue = value.slice(0, value.indexOf('@'));
            const rightValue = value.slice(value.indexOf('@'));
            if (leftValue.length < 2) {
                return value;
            } else {
                const maskedSection = leftValue.slice(0, (leftValue.length / 2));
                const visibleSection = leftValue.slice((leftValue.length / 2));
                return maskedSection.replace(/./g, '*') + visibleSection + rightValue;
            }

        } else if (type === 'phone') {
            const leftVisibleSection = value.slice(0, 2);
            const maskedSection = value.slice(2, 7);
            const rightVisibleSection = value.slice(7);
            return leftVisibleSection + maskedSection.replace(/./g, '*') + rightVisibleSection;

        } else if (type === 'account') {
            const maskedSection = value.slice(0, 7);
            const visibleSection = value.slice(7);
            return maskedSection.replace(/./g, '*') + visibleSection;

        } else if (type === 'name') {
            const long = value.length / 2;
            const maskedSection = value.slice(long, value.length);
            const visibleSection = value.slice(0, long);
            return visibleSection + maskedSection.replace(/./g, '*') ;

        } else if (type === 'iban') {

            const leftVisibleSection = value.slice(0, 5);
            const maskedSection = value.slice(5, 19);
            const rightVisibleSection = value.slice(19);
            return leftVisibleSection + maskedSection.replace(/./g, '*') + rightVisibleSection;
       }



    }
}
