import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'clean' })
export class CleanPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }
    value = value.replace(/ /g, '_');
    // value = value.replace(/[^a-zA-Z0-9_-]/g, '');
    value = value.replace(/_$/, '');
    value = value.replace(/^\_/, '');
    return value;
  }
}
