export class Constants {

    // LOGIN - LOGOUT
    public static SERVICE_AUTH = 'login';
    public static SERVICE_LOGOUT = 'logout';
    public static SERVICE_PIN_CHANGE = 'passwordChange';
    public static PASSWORD_RESCUE = 'passwordRescue';

    // MIF2: Lien entre une personne et la PM
    public static SERVICE_RLS = 'v1/person/rlsLinks';
    public static SERVICE_CRM_GET_CLIENT_DATA = 'v1/crm/clientDatas';
    public static SERVICE_CRM_GetMIF2Answers = 'v1/crm/mif2/answers';
  public static SERVICE_CRM_MIF2VALIDATION_V2 = 'v1/crm/mif2/validation';


    // tan services generic-api
    public static SERVICE_REGISTER_PHONE2 = 'v1/tan/registerPhone';
    public static SERVICE_TAN_PHONE2 = 'v1/tan/status';
    public static SERVICE_REGISTER_TAN2 = 'v1/tan/registerService';
    public static SERVICE_ACTIVATE_TAN2 = 'v1/tan/activateService';
    public static SERVICE_DESACTIVATE_TAN2 = 'v1/tan/deactivateService';
    // public static SERVICE_LOCK_TAN = 'v1/tan/lockService'; //no used

    // session services
    public static SERVICE_SESSION_TAN_STATUS2 = 'v1/tan/status'; // redundant =SERVICE_TAN_PHONE2
    public static SERVICE_REQUEST_TAN2 = 'v1/tan/request';
    public static SERVICE_SESSION_TAN_ACTIVATE2 = 'v1/tan/session/activate';
    public static SERVICE_SESSION_TAN_DESACTIVATE2 = 'v1/tan/session/deactivate';
    public static SERVICE_SESSION_TAN_ATLOGIN2_ACTIVATE = 'v1/tan/sessionAtLogin/activate';
    public static SERVICE_SESSION_TAN_ATLOGIN2_DEACTIVATE = 'v1/tan/sessionAtLogin/deactivate';

    // Captcha
    public static SERVICE_CAPTCHA = 'captcha';

}
