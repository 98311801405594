import { Injectable} from '@angular/core';
import { Observable ,  BehaviorSubject, Subscription } from 'rxjs';
import { MwService } from '../mw/mw.service';
import { StorageService } from './storage.service';
import { GenericService } from '../generic/generic.service';

@Injectable()
export class AuthService {
  loggedIn = new BehaviorSubject<boolean>(false);

  private restrictedMode = new BehaviorSubject<boolean>(false);
  currentRestrictedMode = this.restrictedMode.asObservable();

  public isAuthenticated: boolean;
  userId;
  nip;
  loginReq :Subscription;
  constructor(private mwService: MwService, private genericService: GenericService, private storage: StorageService) {
   }

  public doLogin(userId, nip, siteId): Observable<any> {
    return this.genericService.doLogin(userId, nip, siteId);
  }

  isLoggedIn(): Observable<boolean> {
    if (this.storage.getToken() != null) {
      this.loggedIn.next(true);
      this.isAuthenticated = true;
     } else {
      this.loggedIn.next(false);
      this.isAuthenticated = false;
    }
    return this.loggedIn.asObservable();
  }

  logout(): void {
    this.storage.signOut();
    this.loggedIn.next(false);
  }

  changeRestrictedMode(value: boolean) {
    this.restrictedMode.next(value);
  }
}
