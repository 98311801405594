import { NgModule } from '@angular/core';
import { Routes, RouterModule} from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home',
    data: { title: 'Home | Copartis' },
  pathMatch: 'full', },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, {useHash: true,})],
  imports: [RouterModule.forRoot(routes, { enableTracing: true  ,scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', scrollOffset: [0, 0], onSameUrlNavigation: 'reload'  })],
  exports: []
})
export class WhitelabRoutingModule { }
