import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent,
  HttpResponse, HttpUserEvent, HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { finalize, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private storage: StorageService, private router: Router, private logger: NGXLogger) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    let authReq = req;

    // this.logger.info('on est dans l\'interceptor : ' + authReq.url);

    // if(authReq.url.includes('/netfinca-titres/TDCV2') || authReq.url.includes('/cms/') ) {
    if (!authReq.url.includes('/netfinca-titres/TDCV2')) {
      if (this.storage.getToken() != null) {
        authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, this.storage.getToken()) });
      }
    }

    return next.handle(authReq).pipe(
      tap(
        event => {
          status = '';
          if (event instanceof HttpResponse) {
            status = 'succeeded';
          }
        },
        error => {
          status = 'failed';
          // this.logger.error('token interceptor : ' + authReq.url);
        }
      ),
      finalize(() => {
      })
    );




  }

}

