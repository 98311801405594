import { CdkAccordionModule } from "@angular/cdk/accordion";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule, registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatDatepickerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatStepperModule,
  MatTableModule,
} from "@angular/material";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { AngularFontAwesomeModule } from "angular-font-awesome";
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha";
import { environment } from "../../../../environments/wla/environment";
import { AuthGuardService } from "../../../shared/services/auth/auth-guard.service";
import { AuthService } from "../../../shared/services/auth/auth.service";
import { StorageService } from "../../../shared/services/auth/storage.service";
import { TokenInterceptor } from "../../../shared/services/auth/token-interceptor";
import { CmsService } from "../../../shared/services/cms/cms.service";
import { CstService } from "../../../shared/services/cst/cst.service";
import { MwService } from "../../../shared/services/mw/mw.service";
import { TanService } from "../../../shared/services/tan/tan.service";
import { SharedModule } from "../../shared/shared.module";
import { MdpoubliDialogComponent } from "../private/mdpoubli/mdpoubli-dialog/mdpoubli-dialog.component";
import { LogoutComponent } from "./logout/logout.component";
import { MdpoubliComponent } from "./mdpoubli/mdpoubli.component";
import { routes } from "./private-routing.module";

registerLocaleData(localeFr);

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    BrowserAnimationsModule,
    RouterModule.forChild(routes),
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatIconModule,
    MatTableModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    CdkAccordionModule,
    SharedModule,
    AngularFontAwesomeModule,
    MatButtonModule,
    MatDialogModule,
    RecaptchaV3Module,
  ],
  declarations: [LogoutComponent, MdpoubliComponent, MdpoubliDialogComponent],
  entryComponents: [MdpoubliDialogComponent],
  exports: [RouterModule],
  providers: [
    Title,
    CstService,
    MwService,
    CmsService,
    TanService,
    AuthGuardService,
    AuthService,
    StorageService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
    { provide: MAT_DATE_LOCALE, useValue: "fr-FR" },
  ],
})
export class PrivateModule {}
