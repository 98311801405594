import { formatDate } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogConfig } from "@angular/material";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { NGXLogger } from "ngx-logger";
import { environment } from "../../../../../environments/wla/environment";
import { AlinkService } from "../../../../shared/services/alink/alink.service";
import { BaseModel } from "../../../../shared/services/mw/models/client.model";
import { MwService } from "../../../../shared/services/mw/mw.service";
import { MdpoubliDialogComponent } from "./mdpoubli-dialog/mdpoubli-dialog.component";

@Component({
  selector: "mdpoubli-mdpoubli",
  templateUrl: "./mdpoubli.component.html",
  styleUrls: ["./mdpoubli.component.css"],
})
export class MdpoubliComponent implements OnInit {
  @Input() username = "";
  resp_danger = false;
  resp_warning = false;
  resp_success = false;
  page_principale = true;
  showSpinner = false;
  msg_reponse = "";
  errorOrigin = "";
  errorSeverity = "";
  submitted = false;
  message: BaseModel;
  titlePage = "MOT DE PASSE OUBLIÉ";

  idPageHeader = 0;
  idPageFooter = 0;
  departmentsList: {
    key: string;
    value: string;
  }[];
  recaptcha: any;
  siteKey;
  selfcareForm: FormGroup;
  maxDate = new Date();
  startDate = new Date("1968-01-01");

  // tslint:disable-next-line:max-line-length
  constructor(
    private mwService: MwService,
    public router: Router,
    private titleService: Title,
    private fb: FormBuilder,
    private logger: NGXLogger,
    private alink: AlinkService,
    public dialog: MatDialog,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.creerForm();
    this.siteKey = environment.recaptchaSiteKey;
    this.departmentsListForm();
  }

  ngOnInit() {
    this.alink.changeLoginExpandedStatus(true);
    this.titleService.setTitle(this.titlePage);
    this.idPageHeader = environment.cms_id_MotPasseOublie_header;
    this.idPageFooter = environment.cms_id_MotPasseOublie_footer;
  }

  creerForm() {
    this.selfcareForm = this.fb.group({
      username: ["", [Validators.required, Validators.pattern]],
      birthday: ["", [Validators.required, Validators.pattern]],
      department: ["", [Validators.required, Validators.pattern]],
    });
  }

  departmentsListForm() {
    this.departmentsList = [
      { key: "01 - Ain", value: "01" },
      { key: "02 - Aisne", value: "02" },
      {
        key: "03 - Allier    ",
        value: "03",
      },
      { key: "04 - Alpes-de-Haute-Provence", value: "04" },
      { key: "05 - Hautes-Alpes", value: "05" },
      {
        key: "06 - Alpes-Maritimes",
        value: "06",
      },
      { key: "07 - Ardèche", value: "07" },
      { key: "08 - Ardennes", value: "08" },
      { key: "09 - Ariège", value: "09" },
      {
        key: "10 - Aube",
        value: "10",
      },
      { key: "11 - Aude", value: "11" },
      { key: "12 - Aveyron", value: "12" },
      {
        key: "13 - Bouches-du-Rhône",
        value: "13",
      },
      { key: "14 - Calvados", value: "14" },
      { key: "15 - Cantal", value: "15" },
      {
        key: "16 - Charente",
        value: "16",
      },
      { key: "17 - Charente-Maritime", value: "17" },
      { key: "18 - Cher", value: "18" },
      {
        key: "19 - Corrèze",
        value: "19",
      },
      { key: "2A - Corse-du-Sud", value: "2A" },
      { key: "2B - Haute-Corse", value: "2B" },
      { key: "21 - Côte-d'Or", value: "21" },
      { key: "22 - Côtes-d'Armor", value: "22" },
      {
        key: "23 - Creuse",
        value: "23",
      },
      { key: "24 - Dordogne", value: "24" },
      { key: "25 - Doubs", value: "25" },
      { key: "26 - Drôme", value: "26" },
      {
        key: "27 - Eure",
        value: "27",
      },
      { key: "28 - Eure-et-Loir", value: "28" },
      { key: "29 - Finistère", value: "29" },
      { key: "30 - Gard", value: "30" },
      { key: "31 - Haute-Garonne", value: "31" },
      { key: "32 - Gers", value: "32" },
      { key: "33 - Gironde", value: "33" },
      {
        key: "34 - Hérault",
        value: "34",
      },
      { key: "35 - Ille-et-Vilaine", value: "35" },
      { key: "36 - Indre", value: "36" },
      {
        key: "37 - Indre-et-Loire",
        value: "37",
      },
      { key: "38 - Isère", value: "38" },
      { key: "39 - Jura", value: "39" },
      { key: "40 - Landes", value: "40" },
      { key: "41 - Loir-et-Cher", value: "41" },
      { key: "42 - Loire", value: "42" },
      {
        key: "43 - Haute-Loire",
        value: "43",
      },
      { key: "44 - Loire-Atlantique", value: "44" },
      { key: "45 - Loiret", value: "45" },
      {
        key: "46 - Lot",
        value: "46",
      },
      { key: "47 - Lot-et-Garonne", value: "47" },
      { key: "48 - Lozère", value: "48" },
      {
        key: "49 - Maine-et-Loire",
        value: "49",
      },
      { key: "50 - Manche", value: "50" },
      { key: "51 - Marne", value: "51" },
      { key: "52 - Haute-Marne", value: "52" },
      {
        key: "53 - Mayenne",
        value: "53",
      },
      { key: "54 - Meurthe-et-Moselle", value: "54" },
      { key: "55 - Meuse", value: "55" },
      {
        key: "56 - Morbihan",
        value: "56",
      },
      { key: "57 - Moselle", value: "57" },
      { key: "58 - Nièvre", value: "58" },
      { key: "59 - Nord", value: "59" },
      {
        key: "60 - Oise",
        value: "60",
      },
      { key: "61 - Orne", value: "61" },
      { key: "62 - Pas-de-Calais", value: "62" },
      {
        key: "63 - Puy-de-Dôme",
        value: "63",
      },
      { key: "64 - Pyrénées-Atlantiques", value: "64" },
      { key: "65 - Hautes-Pyrénées", value: "65" },
      {
        key: "66 - Pyrénées-Orientales",
        value: "66",
      },
      { key: "67 - Bas-Rhin", value: "67" },
      { key: "68 - Haut-Rhin", value: "68" },
      {
        key: "69 - Rhône",
        value: "69",
      },
      { key: "70 - Haute-Saône", value: "70" },
      { key: "71 - Saône-et-Loire", value: "71" },
      { key: "72 - Sarthe", value: "72" },
      {
        key: "73 - Savoie",
        value: "73",
      },
      { key: "74 - Haute-Savoie", value: "74" },
      { key: "75 - Paris", value: "75" },
      {
        key: "76 - Seine-Maritime",
        value: "76",
      },
      { key: "77 - Seine-et-Marne", value: "77" },
      { key: "78 - Yvelines", value: "78" },
      {
        key: "79 - Deux-Sèvres",
        value: "79",
      },
      { key: "80 - Somme", value: "80" },
      { key: "81 - Tarn", value: "81" },
      { key: "82 - Tarn-et-Garonne", value: "82" },
      { key: "83 - Var", value: "83" },
      {
        key: "84 - Vaucluse",
        value: "84",
      },
      { key: "85 - Vendée", value: "85" },
      { key: "86 - Vienne", value: "86" },
      { key: "87 - Haute-Vienne", value: "87" },
      {
        key: "88 - Vosges",
        value: "88",
      },
      { key: "89 - Yonne", value: "89" },
      { key: "90 - Territoire de Belfort", value: "90" },
      { key: "91 - Essonne", value: "91" },
      { key: "92 - Hauts-de-Seine", value: "92" },
      {
        key: "93 - Seine-Saint-Denis",
        value: "93",
      },
      { key: "94 - Val-de-Marne", value: "94" },
      { key: "95 - Val-d'Oise", value: "95" },
      {
        key: "971 - Guadeloupe",
        value: "971",
      },
      { key: "972 - Martinique", value: "972" },
      { key: "973 - Guyane", value: "973" },
      {
        key: "974 - La Réunion",
        value: "974",
      },
      { key: "975 - Saint-Pierre-et-Miquelon", value: "975" },
      { key: "976 - Mayotte", value: "976" },
      { key: "978 - Saint-Martin", value: "977" },
      {
        key: "977 - Saint-Barthélemy",
        value: "977",
      },
      { key: "980 - Monaco", value: "980" },
      { key: "986 - Wallis-et-Futuna", value: "986" },
      {
        key: "987 - Polynésie française",
        value: "987",
      },
      { key: "988 - Nouvelle-Calédonie", value: "988" },
      { key: "99 - Etranger (Hors France)", value: "99" },
    ];
  }

  Recuperermdp_confirmation() {
    this.showSpinner = true;
    window.scrollTo(0, 0);
    if (this.selfcareForm.invalid) {
      this.submitted = true;
      this.showSpinner = false;
      return;
    } else {
      this.checkValue();
    }
  }

  changeDepartment(event) {
    this.selfcareForm.controls.department.setValue(event.target.value);
  }

  /** pass to next input when one input is finished */
  onDigitInput(
    event: any,
    previousElement: any,
    nextElement: any,
    maxLength
  ): void {
    if (event.target.value && event.target.value.length === maxLength) {
      nextElement.focus();
    }
  }

  /** captcha */
  checkValue() {
    this.recaptchaV3Service.execute("importantAction").subscribe(
      (token: string) => {
        this.rescurePw(token);
      },
      (error) => {
        this.resp_danger = true;
        this.resp_warning = false;
        this.resp_success = false;
        this.msg_reponse = "Erreur technique: Recaptcha échoué";
        this.openDialogFail(this.msg_reponse);
        this.showSpinner = false;
      }
    );
  }

  rescurePw(token) {
    this.selfcareForm.value.birthday = formatDate(
      this.selfcareForm.value.birthday,
      "dd/MM/yyyy",
      "fr"
    );
    const tempJson = {
      birthDate: this.selfcareForm.value.birthday,
      birthDepartement: this.selfcareForm.value.department,
    };
    this.mwService
      .getBackPwd(tempJson, "cpp", this.selfcareForm.value.username, token)
      .subscribe(
        (reponse) => {
          this.logger.info(
            "le message de réponse : " + JSON.stringify(reponse, null, 2)
          );
          this.resp_danger = false;
          this.resp_warning = false;
          const msg =
              '<b style="color:green">' +
              "Vous allez recevoir votre mot de passe temporaire par SMS* si votre Sécurité Mobile est activée; " +
              "Sinon, il vous sera envoyé par courrier</b>" +
              "<div>*La validité du mot de passe temporaire envoyé par SMS est de 15 minutes</div>";
          this.openDialogSuccess(msg); // JSON.stringify(reponse)
          this.showSpinner = false;
        },
        (error) => {
          this.resp_danger = true;
          this.resp_warning = false;
          this.resp_success = false;
          if (error.status === 500) {
            this.msg_reponse = "Erreur technique";
            this.openDialogFail(this.msg_reponse);
            this.showSpinner = false;
          } else if (error.error) {
            this.msg_reponse = error.error.message;
            this.openDialogFail(this.msg_reponse);
            this.showSpinner = false;
          } else {
            this.msg_reponse = "Erreur technique: unconnu";
            this.openDialogFail(this.msg_reponse);
            this.showSpinner = false;
          }
        }
      );
  }

  openDialogSuccess(message) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message: message,
      title: "Obtention de votre nouveau mot de passe temporaire",
      success: true,
      userId: this.username,
    };
    this.dialog.open(MdpoubliDialogComponent, dialogConfig);
  }

  openDialogFail(message) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message: message,
      title: "Mes informations",
      success: false,
    };
    this.dialog.open(MdpoubliDialogComponent, dialogConfig);
  }
}
