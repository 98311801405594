import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule, MatExpansionModule } from "@angular/material";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgIdleModule } from "@ng-idle/core";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { ModalDialogModule } from "ngx-modal-dialog";
import { AuthGuardService } from "../shared/services/auth/auth-guard.service";
import { AuthService } from "../shared/services/auth/auth.service";
import { StorageService } from "../shared/services/auth/storage.service";
import { TokenInterceptor } from "../shared/services/auth/token-interceptor";
import { CmsService } from "../shared/services/cms/cms.service";
import { CstService } from "../shared/services/cst/cst.service";
import { MwService } from "../shared/services/mw/mw.service";
import { TanService } from "../shared/services/tan/tan.service";
import { PrivateModule } from "./pages/private/private.module";
import { ModalPfComponent } from "./pages/private/profil/mif2/modal-pf/modal-pf.component";
import { PublicModule } from "./pages/public/public.module";
import { SharedModule } from "./shared/shared.module";
import { WhitelabRoutingModule } from "./whitelab-routing.module";
import { WhitelabComponent } from "./whitelab.component";

registerLocaleData(localeFr);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    WhitelabRoutingModule,
    SharedModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatExpansionModule,
    NgIdleModule.forRoot(),
    ModalDialogModule.forRoot(),
    LoggerModule.forRoot({
      serverLoggingUrl: "/api/logs",
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
    PrivateModule,
    PublicModule,
    /*ChartsModule*/
    // TRACE|DEBUG|INFO|LOG|WARN|ERROR|FATAL|OFF
  ],
  entryComponents: [ModalPfComponent],
  declarations: [WhitelabComponent, ModalPfComponent],
  providers: [
    Title,
    CstService,
    MwService,
    CmsService,
    TanService,
    AuthGuardService,
    AuthService,
    StorageService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [WhitelabComponent],
})
export class WhitelabModule {}
