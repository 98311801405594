import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../shared/services/auth/storage.service';
import { environment } from '../../../../environments/wla/environment';
import { CmsService } from '../../../shared/services/cms/cms.service';


@Component({
  selector: 'shared-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
 userId : string;
  constructor(private cmsService: CmsService, private storage : StorageService) { }
  idPage = 0;

  ngOnInit() {
    this.userId = this.storage.getUserId();
    this.idPage = environment.cms_id_footer;
  }

}

