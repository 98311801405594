export class Constants {
  public static ORIGIN = "WEB";

  // LOGIN - LOGOUT
  public static SERVICE_LOGIN = "user/login";
  public static SERVICE_LOGOUT = "logout";
  public static SERVICE_AUTH = "authenticate";
  public static SERVICE_FORGOTPIN = "forgotPin";

  // EMERGENCY MANAGER
  public static EMERGENCY_MANAGER = "user/emergencyManager";

  // API copartis - USER GROUP
  public static SERVICE_USER_NAME = "user/name";
  public static SERVICE_USER_EMAIL = "user/email";
  public static SERVICE_USER_PHONES = "user/phones";
  public static SERVICE_GETBACKPIN = "passwordRescueCPP";

  // public static SERVICE_USER_ACCOUNTS: string = 'user/accounts';

  public static SERVICE_USER_ACCOUNTS = "user/accounts";
  public static SERVICE_USER_REFRESH = "user/allInfo";
  public static SERVICE_USER_ALL_MIF2 = "user/allMif2";

  public static SERVICE_USER_HEADER_INFO = "user/headerInfo";
  public static SERVICE_USER_HEADER_NAME_INFO = "user/headerNameInfo";
  public static SERVICE_USER_HEADER_NAME_INFO_RIB = "user/headerNameInfoRib";
  public static SERVICE_USER_MIF2 = "user/mif2";
  public static SERVICE_VALIDATE_MIF2 = "user/MIF2Validation";

  // Rubrique syntèse des comptes
  public static SERVICE_USER_ACCOUNTS_VALO_MIF2 = "user/accountsValoMif2";
  public static SERVICE_USER_ACCOUNTS_VALO = "user/accountsValo";
  public static SERVICE_USER_ACCOUNTS_SYNTHESE_VALO =
    "user/accountsSyntheseValo";

  getUserAccountsValoMif2;

  public static SERVICE_USER_ACCOUNTS_VALO_XLS = "user/accountsValoXls";
  public static SERVICE_USER_ACCOUNTS_VALO_PDF = "user/accountsValoPdf";
  public static SERVICE_USER_ACCOUNTS_SYNTHESE_COMPTE =
    "account/synthesecompte";

  // Rubrique MIF2
  public static SERVICE_MIF2_SCORES_CONFIG = "mif2/scoresConfig";
  public static SERVICE_MIF2_QFINPP_TEMPLATE = "mif2/QFINPPTemplate";
  public static SERVICE_MIF2_QRSKPP_TEMPLATE = "mif2/QRSKPPTemplate";
  public static SERVICE_MIF2_LOAD_TEMPLATE = "user/loadTemplate";
  public static SERVICE_MIF2_GET_Nationalities = "mif2/nationalites";
  public static SERVICE_MIF2_GET_CspCodes = "mif2/cspCodes";
  public static SERVICE_MIF2_GET_PAYS = "mif2/pays";
  public static SERVICE_MIF2_GET_Secteurs = "mif2/secteurDActivite";
  public static SERVICE_MIF2_GET_Professions = "mif2/professions";
  public static SERVICE_MIF2_SET_Answers = "mif2/setAnswers";

  // A priori pas important
  public static SERVICE_USER_ADDRESSES = "user/addresses";
  public static SERVICE_USER_TECHNICAL_INFO = "user/technicalInfo";

  public static SERVICE_LOGIN_INFO = "user/logininfo";
  public static SERVICE_TAN_INFO = "user/taninfo";

  // API copartis - ACCOUNT GROUP
  public static SERVICE_ACCOUNT_RIB_PDF = "account/ribpdf";
  public static SERVICE_ACCOUNT_CHEQUE_PDF = "account/chequepdf";

  // API copartis - CAT GROUP
  public static SERVICE_CAT_SSO_CONNECT = "cat/ssoConnect";

  public static SERVICE_PIN_CHANGE = "user/pinchange";
  public static SERVICE_RIB = "/rib2";
  public static SERVICE_CRM_GET_MIF2_DEFINITION = "/crmGetMif2Definition";

  public static SERVICE_CRM_GET_CLIENT_DATA = "MWCrmGetClientDatas";
  public static SERVICE_MW_ACCOUNTS_OVERVIEW = "MWAccountsOverview";
  public static SERVICE_MW_CrmSetEmail = "MWCrmSetEmail";
  public static SERVICE_MW_CrmSetPhones = "MWCrmSetPhones";
  public static SERVICE_MW_CrmGetMIF2Answers = "user/crmGetMIF2Answers";
  // tan services
  public static SERVICE_REGISTER_PHONE = "tan/registerPhone";
  public static SERVICE_TAN_PHONE = "tan/TanPhone";
  public static SERVICE_REGISTER_TAN = "tan/register";
  public static SERVICE_ACTIVATE_TAN = "tan/activate";
  public static SERVICE_DESACTIVATE_TAN = "tan/desactivate";

  public static SERVICE_SESSION_TAN_STATUS = "SessionTan/status";
  public static SERVICE_REQUEST_TAN = "tan/";
  public static SERVICE_SESSION_TAN_ACTIVATE = "SessionTan/activate";
  public static SERVICE_SESSION_TAN_DESACTIVATE = "SessionTan/desactivate";
  public static SERVICE_SESSION_TAN_ATLOGIN = "SessionTan/atLogin";

  // tan services generic-api
  public static SERVICE_REGISTER_PHONE2 = "v1/tan/registerPhone";
  public static SERVICE_TAN_PHONE2 = "v1/tan/status";
  public static SERVICE_REGISTER_TAN2 = "v1/tan/registerService";
  public static SERVICE_ACTIVATE_TAN2 = "v1/tan/activateService";
  public static SERVICE_DESACTIVATE_TAN2 = "v1/tan/deactivateService";
  //public static SERVICE_LOCK_TAN = 'v1/tan/lockService'; //no used

  public static SERVICE_SESSION_TAN_STATUS2 = "v1/tan/status"; //redundant
  public static SERVICE_REQUEST_TAN2 = "v1/tan/request";
  public static SERVICE_SESSION_TAN_ACTIVATE2 = "v1/tan/session/activate";
  public static SERVICE_SESSION_TAN_DESACTIVATE2 = "v1/tan/session/deactivate";
  public static SERVICE_SESSION_TAN_ATLOGIN2_ACTIVATE =
    "v1/tan/sessionAtLogin/activate";
  public static SERVICE_SESSION_TAN_ATLOGIN2_DEACTIVATE =
    "v1/tan/sessionAtLogin/deactivate";

  // AsterionDocs
  public static SERVICE_AsterionDocs_SEARCH = "onlineDocs/search";

  // iban

  public static SERVICE_ContractGroup_Load = "iban/load/contractGroup";
  public static SERVICE_Ibans_Load = "iban/get";
  public static SERVICE_Iban_delete = "iban/delete";
  public static SERVICE_Iban_add = "iban/add";

  //  PZP Entreprise
  public static SERVICE_Entreprise = "entreprisePzp/quotidienBoursier";
  public static SERVICE_EntreprisePdf = "entreprisePzp/quotidienBoursierPdf";
  public static SERVICE_OPERATIONS_GET = "entreprisePzp/operations";
  public static SERVICE_OPERATIONS_GET_PDF = "entreprisePzp/operationsPdf";
  public static SERVICE_OPERATIONS_GET_XLS = "entreprisePzp/operationsXls";

  // Messages : MW SERVICE

  public static SERVICE_INTERNAL_ERROR =
    " service indisponible, veuillez réessayer ultérieurement.";
  public static SERVICE_ACCES_DENIED =
    "L'accès à cette fonctionnalité est temporairement indisponible. veuillez réessayer ultérieurement.";

  // Messages : Securite

  public static SECURITE_Phone_Format =
    "Veuillez saisir un numéro de téléphone valide.";

  // Messages : coordonnee

  public static COORDONNEE_Mail_Format = "Veuillez saisir un email valide !";
  public static COORDONNEE_Mail_Success =
    "Votre adresse email a été changée avec succès.";
  public static COORDONNEE_PhoneD_Format =
    "Veuillez saisir un numéro de téléphone domicile valide. (ce numéro doit commencer par le préfixe 01, 02, 03, 04, ou 05)";
  public static COORDONNEE_PhoneP_Format =
    "Veuillez saisir un numéro de téléphone portable valide. (ce numéro doit commencer par le préfixe 06 ou 07)";
  public static COORDONNEE_PhonePr_Format =
    "Veuillez saisir un numéro de téléphone Professionel  valide.";
  public static COORDONNEE_Phone_Format =
    "Veuillez saisir des numéros de téléphone valides.";
  public static COORDONNEE_Phone_Success =
    "Vos numéros de téléphone ont été changés avec succès.";

  // Messages : Session

  public static SESSION_checkbox_message =
    "Merci de cocher la case pour accepter les conditions de sécurité.";

  // Messages : Login

  public static LOGIN_Field_required =
    "les champs identifiant et mot de passe sont obligatoires";

  // Messages : Virement

  public static VIREMENT_confirmation_suppression =
    "Vous êtes sur le point de supprimer un compte bénéficiaire. Voulez-vous continuer ? ";
  public static VIREMENT_message_suppression =
    "Le compte bénéficiaire a bien été supprimé";

  // Messages : Add

  public static ADD_message_success = "Votre demande a bien été enregistrée ";

  // psa

  //pzp
  // Messages :  activation session

  public static Activation_SESSION_TAN_M =
    "Activer une session vous permettra de recevoir un code SMS unique pour passer l'ensemble de vos ordres lors de votre session.<br> Vous pouvez choisir de l'activer dès à présent. A défaut, vous pourrez activer une session à tout moment en cliquant sur le lien sous le bouton Quitter ou vous pourrez <br>demander un code SMS unique pour valider vos ordres.";

  /*clm */
  public static SERVICE_Sso_Login = "/sso/ssoLogin";
  public static SERVICE_CetelemSessionInfo = "sso/clmSession";
  public static SERVICE_CashTransfer_Get = "cashTransfer/getList";
  public static SERVICE_CashTransfer = "cashTransfer/create";
  public static SERVICE_CashTransfer_Cancel = "cashTransfer/cancel";
  public static SERVICE_CashTransfer_LoadAccounts = "cashTransfer/getAccounts";
  public static SERVICE_GET_SAVING_PLAN = "splan/get";
  public static SERVICE_GET_SAVINGS_PLAN_CHANGE_INQ = "splan/getPendingSP";
  public static SERVICE_CHANGE_SAVINGS_PLAN = "splan/update";
  public static SERVICE_CANCEL_SAVINGS_PLAN_CHANGE = "splan/cancel";
  public static SERVICE_CREATE_SAVINGS_PLAN_CHANGE = "splan/change";
  public static SERVICE_CHECK_SAVINGS_PLAN_CHANGE = "splan/checkDates";
  public static SERVICE_USER_CLM_ACCOUNTS = "accountInfo/accounts";
  public static SERVICE_CLM_ContractGroup_Load =
    "accountInfo/load/contractGroup";
  public static SERVICE_CLM_ACCOUNT_RIB_PDF = "accountInfo/ribpdf";
  public static SERVICE_CLM_ACCOUNT_CHEQUE_PDF = "accountInfo/chequepdf";
  public static SERVICE_CLM_CashInvActInq = "cashTransfer/CashInvActInq";
  public static SERVICE_CLM_CashTurnoverInq = "accountInfo/CashTurnOverInq";

  public static PCOM_ROLE = "PCOM";
  //public static PCOM_ROLE ='ECPF';
}
