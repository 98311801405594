import { Component, OnInit } from '@angular/core';
import { CmsService } from '../../../shared/services/cms/cms.service';
import { environment } from '../../../../environments/wla/environment';


@Component({
  selector: 'shared-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

  idPage = 0;

  constructor( private cmsService: CmsService) { }

  ngOnInit() {
    this.idPage = environment.cms_id_info;
   }

}
