import {Component, OnInit, Inject, Input} from '@angular/core';
import { MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material';
import {Router} from '@angular/router';
import {AlinkService} from '../../../../../shared/services/alink/alink.service';
export class dialogData {
  message: string;
  title: string;
  success: boolean;
  userId: string;
}



@Component({
  selector: 'app-mdpoubli-dialog',
  templateUrl: './mdpoubli-dialog.component.html',
  styleUrls: ['./mdpoubli-dialog.component.css']
})
export class MdpoubliDialogComponent  {

  info: dialogData;
  constructor(
    public dialogRef: MatDialogRef<MdpoubliDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public router: Router,
    private alink: AlinkService) {
     this.info = data;
     }

  onYesClick(): void {
    this.dialogRef.close(true);
    this.alink.changeUserIdStatus(this.info.userId);
    this.router.navigateByUrl('/login');
  }

  onNoClick(): void {
    this.dialogRef.close(true);
  }

}
