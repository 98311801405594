// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: '/api/v1/',
  psaUrl: '/api-psa/internal/v1/',
  rciPcUrl: '/api-rci/internal/v1/',
  rciUrl: '/api-rci/priv/',
  gtwRciUrl: '/api-rci/pub/v1/',
  gtwUrl: '/auth/v1/',
  cmsUrl: '/cms/v1/',
  cstJsonFile: 'assets/shared/constants/data.json',
  genericPcUrl : '/api-generic/internal/v1/',
  genericUrl : '/api-generic/',
  genericPubUrl : '/api-generic/pub/v1/',
  genericAuthUrl : '/api-generic/v1/auth/',
  //apiKey:'d4f66a6a-504d-4321-8d69-87543d180a25',
  apiKey:'df3276c9-2dd5-4e65-8b8e-4a1e37314595',
  application: 'PZP',
};

