import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule, MatIconModule } from "@angular/material";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Title } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { AuthService } from "../../../shared/services/auth/auth.service";
import { StorageService } from "../../../shared/services/auth/storage.service";
import { TokenInterceptor } from "../../../shared/services/auth/token-interceptor";
import { CmsService } from "../../../shared/services/cms/cms.service";
import { CstService } from "../../../shared/services/cst/cst.service";
import { MwService } from "../../../shared/services/mw/mw.service";
import { TanService } from "../../../shared/services/tan/tan.service";
import { SharedModule } from "../../shared/shared.module";
import { DialogNotificationHomeComponent } from "./home/dialog-notification-home/dialog-notification-home.component";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { routes } from "./public-routing.module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
    }),
    FormsModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatExpansionModule,
    MatTooltipModule,
    MatDialogModule,
    MatIconModule,
  ],
  declarations: [
    HomeComponent,
    LoginComponent,
    DialogNotificationHomeComponent,
  ],
  entryComponents: [DialogNotificationHomeComponent],
  exports: [RouterModule],
  providers: [
    Title,
    CstService,
    MwService,
    CmsService,
    TanService,
    AuthService,
    StorageService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
})
export class PublicModule {}
